import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Style/Style.css';
import './Style/Mediaquries.css'
import 'wowjs/css/libs/animate.css';
import 'animate.css';
 import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
 import Home from './Pages/Home/Home';
import Contact from './Pages/Contactus/Contact';
import About from './Pages/Aboutus/Aboutus';
import Hotels from './Pages/Hotels/Hotel';
import HotelDetail from './Pages/Hotels/HotelDetail';
import BookRoom from './Pages/BookRoom/BookRoom';
import Confirmation from './Pages/BookingConfirmation/Confirmation';
import FaqPage from './Pages/FooterPages/faqPage';
import PrivacyPolicy from './Pages/FooterPages/PrivacyPolicyPage';
import TermsConditions from './Pages/FooterPages/TermsConditions';
import ComplaintPolicy from './Pages/FooterPages/ComplaintPolicy';
import TransferListing from './Pages/Transfer/TransferListing';
import TransferCheckout from './Pages/Transfer/TransferCheckout';
import TransferInvoice from './Pages/Transfer/TransferInvoice';
import AboutSaudi from './Pages/KnowBeforeGo/AboutSaudi'
import TravelTips from './Pages/KnowBeforeGo/TravelTips'
import UsefulContacts from './Pages/KnowBeforeGo/UsefulContacts'
import HotelInvoice from './Pages/BookingConfirmation/HotelInvoice';
import ScrollToTop from './Components/ScrollTop';
import NotFoundPage from './404page';
import Emailvarify from './Pages/EmailVarify/Emailvarify';
function App() {
  return (
   <>
   <Router>
   <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/hotel-listing" element={<Hotels />} />
      <Route path="/hotel-detail/:id" element={<HotelDetail />} />
      <Route path="/hotel-checkout" element={<BookRoom />} />
      <Route path="/hotel-invoice/:id" element={<HotelInvoice />} />
      <Route path="/hotel-booking-invoice/:id" element={<Confirmation />} />
      <Route path="/faqs" element={<FaqPage />} />
      <Route path="/complaint-policy" element={<ComplaintPolicy/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/terms-and-conditions" element={<TermsConditions/>} />
      <Route path="/transfer-search" element={<TransferListing />} />
      <Route path="/transfer-checkout" element={<TransferCheckout />} />
      <Route path="/transfer-invoice/:id" element={<TransferInvoice />} />
      <Route path="/about-saudi" element={<AboutSaudi />} />
      <Route path="/travel-tips" element={<TravelTips />} />
      <Route path="/useful-contacts" element={<UsefulContacts />} />
      <Route path="/view-bookings" element={<Emailvarify />} />
      
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    </Router>
   
   </>
  );
}

export default App;
