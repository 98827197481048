import React, { useState, useEffect } from 'react'
import customer from '../../Images/HH.png'
import cityimg from '../../Images/Hotels/saudia.jpeg'
import makkah from '../../Images/Hotels/2.jpg'
import madinah from '../../Images/Hotels/1.jpg'
import jeddah from '../../Images/Hotels/jeddah.jpg'
import riyadh from '../../Images/Hotels/riyadh.webp'
import dammam from '../../Images/Hotels/dammam.webp'
import abaha from '../../Images/Hotels/abha.jpeg'
import alula from '../../Images/Hotels/alula.jpg'
import makkn from '../../Images/Hotels/makkah.jpg'
import { Modal, DateRangePicker, Popover, Whisper } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
const { beforeToday } = DateRangePicker
function SaudiaCity () {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [location, setLocation] = useState('')
  const [selectDate, setSelectDate] = useState(null)
  const [userLocation, setUserLocation] = useState([])
  const [isWhisperOpen, setIsWhisperOpen] = useState(false)
  const [childAgesError, setChildAgesError] = useState({})
  const [validation, setValidation] = useState('')
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1
  })
  const [rooms, setRooms] = useState([
    {
      adults: 2,
      children: 0,
      childrenAges: []
    }
  ])
  const handleOpen = name => {
    setLocation(name)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  useEffect(() => {
    getUserLocation()
  }, [])
  useEffect(() => {
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0)
    const children = rooms.reduce((acc, item) => acc + item.children, 0)
    const roomss = rooms.length
    setPersonData(prevPersonData => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss
    }))
  }, [rooms])
  function getUserLocation () {
    // Fetch user's location data
    fetch(
      'https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041'
    )
      .then(response => response.json())
      .then(data => {
        setUserLocation(data)
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error)
      })
  }
  const handledatechange = date => {
    setSelectDate(date)
  }
  const handleCloseWhisper = () => {
    let ageStatus = false
    let messages = {} // Store error messages for each room and child

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i]

      if (children !== childrenAges.length) {
        for (let j = 0; j < children; j++) {
          if (!childrenAges[j]) {
            // Check if age is missing for a specific child
            messages[`room-${i}-child-${j}`] = `Please select child age.`
            ageStatus = true
          }
        }
      }
    }

    if (ageStatus) {
      setChildAgesError(messages) // Store messages for each specific child missing an age
      return
    }

    setChildAgesError({})
    setIsWhisperOpen(false)
  }
  const handleAdultIncrement = roomIndex => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1
      setRooms(updatedRooms)
    }
  }
  const handleAdultDecrement = roomIndex => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1
      updatedRooms[roomIndex].childrenAges.pop()
      setRooms(updatedRooms)
    }
  }
  const handleChildrenIncrement = roomIndex => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1
      setRooms(updatedRooms)
    }
  }
  const handleChildrenDecrement = roomIndex => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1
      updatedRooms[roomIndex].childrenAges.pop()
      setRooms(updatedRooms)
    }
  }
  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms]

    if (value === '') {
      // Remove the age for this child if value is empty
      updatedRooms[roomIndex].childrenAges = updatedRooms[
        roomIndex
      ].childrenAges.filter((_, index) => index !== childIndex)
    } else {
      // Update or add the age if value is not empty
      updatedRooms[roomIndex].childrenAges[childIndex] = value
    }

    setRooms(updatedRooms)
  }
  const addRoom = () => {
    // Add a new room with default values
    if (rooms.length < 9) {
      setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }])
    }
  }
  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1))
    }
  }
  const handleDeleteRoomByIndex = roomIndex => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex)
    setRooms(updatedRooms)
  }
  const childAgearray = [
    '1 Year',
    '2 Year',
    '3 Year',
    '4 Year',
    '5 Year',
    '6 Year',
    '7 Year',
    '8 Year',
    '9 Year',
    '10 Year'
  ]
  const Searchhotels = async name => {
    if (selectDate === null) {
      setValidation('Please select checkin and checkout dates.')
      return
    }
    var destination_name = ''
    var country = ''
    var lat = ''
    var lon = ''
    var pin = ''
    if (location === 'makkah') {
      destination_name = 'Makkah'
      country = 'Saudi Arabia'
      lat = 21.4240968
      lon = 39.81733639999999
      pin = 'SA'
    } else if (location === 'madinah') {
      destination_name = 'Madinah'
      country = 'Saudi Arabia'
      lat = 24.4672132
      lon = 39.6024496
      pin = 'SA'
    } else if (location === 'jeddah') {
      destination_name = 'Jeddah'
      country = 'Saudi Arabia'
      lat = 21.5291545
      lon = 39.1610863
      pin = 'SA'
    } else if (location === 'riyadh') {
      destination_name = 'Riyadh'
      country = 'Saudi Arabia'
      lat = 24.7135517
      lon = 46.6752957
      pin = 'SA'
    } else if (location === 'dammam') {
      destination_name = 'Dammam'
      country = 'Saudi Arabia'
      lat = 26.4206828
      lon = 50.0887943
      pin = 'SA'
    } else if (location === 'alula') {
      destination_name = 'AlUla'
      country = 'Saudi Arabia'
      lat = 26.6031391
      lon = 37.929482
      pin = 'SA'
    } else if (location === 'abha') {
      destination_name = 'Abha'
      country = 'Saudi Arabia'
      lat = 18.2464685
      lon = 42.5117238
      pin = 'SA'
    } else if (location === 'taif') {
      destination_name = 'Taif'
      country = 'Saudi Arabia'
      lat = 21.2840782
      lon = 40.42481919999999
      pin = 'SA'
    }
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      destination_name: destination_name,
      country: country,
      lat: lat,
      long: lon,
      pin: pin,
      cityd: destination_name,
      country_code: pin,
      check_in: moment(selectDate[0]).format('YYYY-MM-DD'),
      check_out: moment(selectDate[1]).format('YYYY-MM-DD'),
      slc_nationality: userLocation.country.name,
      adult: 2,
      child: 0,
      room: 1,
      Adults: [2],
      children: [0],
      child_ages1: [],
      rooms_counter: [1],
      child_ages2: []
    }
    const queryString = new URLSearchParams(FormData).toString()
    navigate(`/hotel-listing?${queryString}`)
   
  }
  const modalocationchange = event => {
    setLocation(event.target.value)
  }
  const speaker = (
    <Popover style={{ width: '21em' }} title='Select Detail'>
      {rooms.map((room, roomIndex) => (
        <div key={roomIndex} className='availability_search'>
          <div
            className='hotel-detail-border d-flex text-center fw-bold mb-2'
            style={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <p className='fw-bold m-1'>Room {roomIndex + 1}</p>
            {roomIndex > 0 && ( // Check if roomIndex is greater than 0
              <button
                className='delete-room-button m-1'
                onClick={() => handleDeleteRoomByIndex(roomIndex)}
              >
                Delete
              </button>
            )}
          </div>
          <div>
            <div className='select-child'>
              <div class='f4878764f1'>
                <label class='a984a491d9 fw-bold' for='group_adults'>
                  Adults
                </label>
              </div>
              <div class='d-flex fff'>
                <button
                  className='adult-modal-btn'
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultDecrement(roomIndex)}
                >
                  <i class='fas fa-minus'>
                    <FontAwesomeIcon size='sm' icon={faMinus} />
                  </i>
                </button>
                <span className='d723d73d5f fw-bold' id='input'>
                  {room.adults}
                </span>
                <button
                  className='adult-modal-btn'
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultIncrement(roomIndex)}
                  id='increment'
                >
                  <i class='fas fa-plus'>
                    <FontAwesomeIcon size='sm' icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className='select-child'>
              <div class='f4878764f1'>
                <label class='a984a491d9 fw-bold' for='group_adults'>
                  Children
                </label>
              </div>
              <div class='d-flex fff'>
                <button
                  className='adult-modal-btn'
                  onClick={() => handleChildrenDecrement(roomIndex)}
                  id='decrement'
                >
                  <i class='fas fa-minus'>
                    <FontAwesomeIcon size='sm' icon={faMinus} />
                  </i>
                </button>
                <span className='d723d73d5f fw-bold' id='input'>
                  {room.children}
                </span>
                <button
                  className='adult-modal-btn'
                  onClick={() => handleChildrenIncrement(roomIndex)}
                  id='increment'
                >
                  <i class='fas fa-plus'>
                    <FontAwesomeIcon size='sm' icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className='select-child' style={{ justifyContent: 'left' }}>
              {room.children > 0 && (
                <div
                  style={{ flexWrap: 'wrap' }}
                  className='d-flex justify-content-between'
                >
                  {Array.from({ length: room.children }, (_, childIndex) => (
                    <div key={childIndex} className='m-1 '>
                      <label>Child {childIndex + 1} (Age)</label>
                      <select
                        class='form-select child-age-select'
                        name='child1'
                        value={room.childrenAges[childIndex]}
                        onChange={e =>
                          handleChildAgeChange(
                            roomIndex,
                            childIndex,
                            e.target.value
                          )
                        }
                        aria-label='Default select example'
                      >
                        <option value='' selected>
                          Age needed
                        </option>
                        {childAgearray.map((item, index) => (
                          <option key={index} value={index + 1}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {childAgesError[
                        `room-${roomIndex}-child-${childIndex}`
                      ] && (
                        <p className='text-danger'>
                          {
                            childAgesError[
                              `room-${roomIndex}-child-${childIndex}`
                            ]
                          }
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div>
        <div className='select-child availability_search mt-4'>
          <div class='f4878764f1 pe-4'>
            <label class='a984a491d9 fw-bold' for='group_adults'>
              Room
            </label>
          </div>
          <div class='d-flex fff'>
            <button
              className='adult-modal-btn'
              onClick={removeLastRoom}
              id='decrement'
            >
              <i class='fas fa-minus'>
                <FontAwesomeIcon size='sm' icon={faMinus} />
              </i>
            </button>
            <span className='d723d73d5f fw-bold' id='input'>
              {rooms.length}
            </span>
            <button
              className='adult-modal-btn'
              onClick={addRoom}
              id='increment'
            >
              <i class='fas fa-plus'>
                <FontAwesomeIcon size='sm' icon={faPlus} />
              </i>
            </button>
          </div>
          <button
            onClick={handleCloseWhisper}
            className='theme-btn-1  ps-3 pe-3 py-1'
          >
            Apply
          </button>
        </div>
      </div>
    </Popover>
  )

  return (
    <>
      {/* Hotels Section */}

      <Modal
        backdrop='static'
        className='home-city-modal'
        open={open}
        onClose={handleClose}
      >
        <Modal.Header>
          <Modal.Title>Search Criteria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='p-2'>
            <label>Location</label>
            <select
              value={location}
              onChange={modalocationchange}
              class='form-select'
              aria-label='Default select mt-1 example'
            >
              <option value='makkah'>Makkah</option>
              <option value='madinah'>Medinah</option>
              <option value='jeddah'>Jeddah</option>
              <option value='riyadh'>Riyadh</option>
              <option value='dammam'>Dammam</option>
              <option value='alula'>Alula</option>
              <option value='abha'>Abha</option>
              <option value='taif'>Taif</option>
            </select>
          </div>
          <div
            style={{ flexWrap: 'wrap' }}
            className='home-adult-modal w-100 justify-content-between p-2 '
          >
            <div className=''>
              <label>Select Dates</label>
              <div>
                <DateRangePicker
                  className='w-100'
                  disabledDate={beforeToday()}
                  format='dd-MM-yyyy'
                  value={selectDate}
                  onChange={handledatechange}
                />
              </div>
            </div>
            <div className=''>
              <label>Select Rooms</label>
              <div>
                <div className='field-icon-wrap'>
                  <Whisper
                    open={isWhisperOpen}
                    placement='bottom'
                    speaker={speaker}
                    preventClose
                  >
                    <button
                      onClick={() => setIsWhisperOpen(true)}
                      className='btn w-100 text-left  btn-primary btn-block select-styling button-2 search-btn1'
                      style={{
                        color: 'black',
                        background: 'none',
                      
                      }}
                    >
                      {personData.adult}{' '}
                      {Number(personData.adult) > 1 ? 'Adults' : 'Adult'} .{' '}
                      {personData.children}{' '}
                      {Number(personData.children) > 1
                        ? 'Childrens'
                        : 'Children'}{' '}
                      . {personData.room}{' '}
                      {Number(personData.room) > 1 ? 'Rooms' : 'Room'}
                    </button>
                  </Whisper>
                </div>
              </div>
            </div>
          </div>
          {validation && <p className='text-danger'>{validation}</p>}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={Searchhotels} className='btn me-2 btn-success'>
            Search
          </button>
          <button onClick={handleClose} className='btn btn-secondary'>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <section class='gray-simple home-city mt-2'>
        <div class='container'>
          <div class='row align-items-center justify-content-center'>
            <div class='col-xl-8 col-lg-9 col-md-11 col-sm-12'>
              <div class='secHeading-wrap text-center mb-5'>
                <h1 className='text-black fw-bold h2'>Popular Location To Stay</h1>
                {/* <p>Cicero famously orated against his political opponent Lucius Sergius Catilina.</p> */}
              </div>
            </div>
          </div>

          <div class='row align-items-center justify-content-center g-xl-4 g-lg-4 g-3'>
            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs'>
                    <div class='destinations-pics  cursor-pointer'>
                      <a onClick={() => handleOpen('makkah')}>
                        <img src={makkah} class='img-fluid' alt='makkah' />
                      </a>
                    </div>
                  </div>
                  <div class='destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2'>
                    <div class='exploterr-text'>
                      <h3 class='text-light fw-bold mb-1'>Makkah</h3>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs'>
                    <div class='destinations-pics  cursor-pointer'>
                      <a onClick={() => handleOpen('madinah')}>
                        <img src={madinah} class='img-fluid' alt='madinah' />
                      </a>
                    </div>
                  </div>
                  <div class='destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2'>
                    <div class='exploterr-text'>
                      <h3 class='text-light fw-bold mb-1'>Medinah</h3>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs'>
                    <div class='destinations-pics  cursor-pointer'>
                      <a onClick={() => handleOpen('jeddah')}>
                        <img src={jeddah} class='img-fluid' alt='jeddah' />
                      </a>
                    </div>
                  </div>
                  <div class='destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2'>
                    <div class='exploterr-text'>
                      <h3 class='text-light fw-bold mb-1'>Jeddah</h3>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs'>
                    <div class='destinations-pics  cursor-pointer'>
                      <a onClick={() => handleOpen('riyadh')}>
                        <img src={riyadh} class='img-fluid' alt='riyadh' />
                      </a>
                    </div>
                  </div>
                  <div class='destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2'>
                    <div class='exploterr-text'>
                      <h3 class='text-light fw-bold mb-1'>Riyadh</h3>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs'>
                    <div class='destinations-pics  cursor-pointer'>
                      <a onClick={() => handleOpen('dammam')}>
                        <img src={dammam} class='img-fluid' alt='dammam' />
                      </a>
                    </div>
                  </div>
                  <div class='destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2'>
                    <div class='exploterr-text'>
                      <h3 class='text-light fw-bold mb-1'>Dammam</h3>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs'>
                    <div class='destinations-pics  cursor-pointer'>
                      <a onClick={() => handleOpen('alula')}>
                        <img src={alula} class='img-fluid' alt='alula' />
                      </a>
                    </div>
                  </div>
                  <div class='destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2'>
                    <div class='exploterr-text'>
                      <h3 class='text-light fw-bold mb-1'>Alula</h3>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs'>
                    <div class='destinations-pics  cursor-pointer'>
                      <a onClick={() => handleOpen('abha')}>
                        <img src={abaha} class='img-fluid' alt='abaha' />
                      </a>
                    </div>
                  </div>
                  <div class='destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2'>
                    <div class='exploterr-text'>
                      <h3 class='text-light fw-bold mb-1'>Abha</h3>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-6 col-sm-6'>
              <div class='card destination-card border-0 rounded-3 overflow-hidden m-0'>
                <div class='destination-card-wraps position-relative'>
                  <div class='destination-card-thumbs'>
                    <div class='destinations-pics  cursor-pointer'>
                      <a onClick={() => handleOpen('taif')}>
                        <img src={cityimg} class='img-fluid' alt='cityimg' />
                      </a>
                    </div>
                  </div>
                  <div class='destination-card-description position-absolute start-0 bottom-0 ps-4 pb-4 z-2'>
                    <div class='exploterr-text'>
                      <h3 class='text-light fw-bold mb-1'>Taif</h3>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section style={{ marginTop: '3em' }}>
        <div class='container'>
          <div class='row align-items-center justify-content-between'>
          <div class='col-xl-5 col-lg-6 col-md-6'>
              <div class='bestExperience-block'>
                <h4 class='fw-bold lh-base text-black'>
                Welcome to Haramayn  Hotels - The Ultimate Accommodation for Makkah & Medina Pilgrims
                </h4>
                <p
                  class='fw-light fs-6 text-justify mt-4'
                  style={{ color: '#5d6f7d' }}
                >
                  Whether you're traveling for Umrah, Hajj, or simply visiting the holy cities for spiritual reflection, finding the right place to stay is essential. At Haramayn  Hotels, we help you book the most comfortable, affordable, and convenient hotels in Makkah and Medina.{' '}
                </p>
                
              </div>
            </div>
            <div class='col-xl-5 col-lg-5 col-md-6'>
              <div class='position-relative'>
                <img
                  src={makkn}
                  class='img-fluid rounded-3 position-relative z-1'
                  alt='makkn'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default SaudiaCity
