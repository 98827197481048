import React,{useState,useEffect} from "react";
import { Hotelapitoken,ApiEndPoint,CustomerDomainName } from '../GlobalData/GlobalData';
import Loading from "../Loading/Loader";
import { useNavigate,NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer,toast } from 'react-toastify';
import fb from '../../Images/fb_logo.png'
import twitter from '../../Images/twitter_logo.png'
import insta from '../../Images/insta.png'
import moment from 'moment';
function Footer(){
  const [isLoading, setIsLoading] = useState(false);
  const [userLocation,setUserLocation]=useState([]);
  const navigation=useNavigate();
  useEffect(()=>{
    getUserLocation();
  },[]);
  function getUserLocation() {
    // Fetch user's location data
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
      .then(response => response.json())
      .then(data => {
        setUserLocation(data);
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }

  const Searchhotels = async (name) => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    var destination_name='';
     var country='';
     var lat='';
     var lon='';
     var pin='';
    if(name==='Makkah'){
     destination_name="Makkah";
     country="Saudi Arabia";
     lat=21.4240968;
     lon=39.81733639999999;
     pin="SA";
    }else if(name==='Madinah'){
     destination_name="Madinah";
     country="Saudi Arabia";
     lat=24.4672132;
     lon=39.6024496;
     pin="SA";
    }else if(name==='Riyadh'){
     destination_name="Riyadh";
     country="Saudi Arabia";
     lat=24.7135517;
     lon=46.6752957;
     pin="SA";
    }else if(name==='Jeddah'){
      destination_name="Jeddah";
      country="Saudi Arabia";
      lat=21.5291545;
      lon=39.1610863;
      pin="SA";
     }
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
      "destination_name":destination_name,
      "country":country,
      "lat":  lat,
      "long": lon,
      "pin": pin,
      "cityd":destination_name,
      "country_code": pin,
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality":userLocation.country.name,
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    const queryString = new URLSearchParams(FormData).toString();
    navigation(`/hotel-listing?${queryString}`)
  };
    return(
    <>
    <ToastContainer/>
    {isLoading && ( <Loading/>)}
        <div class="uitk-spacing uitk-spacing-padding-block-three uitk-spacing-padding-inlinestart-two uitk-spacing-padding-inlineend-two">
          <h2 class="uitk-heading uitk-heading-4 is-visually-hidden">Additional information about Mufti Travel</h2>
          <footer class="global-navigation-footer">
            <div class="global-navigation-footer-container">
              <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-unset uitk-spacing-padding-inlinestart-six uitk-spacing-padding-blockstart-six  uitk-spacing-padding-small-inlineend-three uitk-spacing-padding-medium-inlineend-three uitk-spacing-padding-large-inlineend-two uitk-spacing-padding-extra_large-inlineend-two">
                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-spacing uitk-spacing-padding-blockend-two uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width" role="list">               
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Need any help?</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                      <div class="footer_inquery_area">
                            <h5>Call 24/7 for any help</h5>
                            <h4> <a href="tel:+966 509 786777">+966 509 786777</a></h4>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                      <div class="footer_inquery_area">
                            <h5>Mail to our support team</h5>
                            <h4> <a href="mailto:support@domain.com">info@haramaynhotels.com</a></h4>
                        </div>
                      </li>
                    </ul>
                    <div className="widget newsletter-widget">
                    <div className="footer-newsletter">
                      <div className="ms-2 ">
                        <a className="logo_hover" href="https://www.facebook.com/@HaramaunHotels/" target="_blank"> <img src={fb} alt="Facebook Logo" width="30px"/></a>
                        <a className="logo_hover ms-4" href="https://www.instagram.com/haramaynhotels1" target="_blank"> <img src={insta} alt="Insta Logo" height="40px" width="40px"/></a>
                        <a className="logo_hover ms-4"  target="_blank"> <img src={twitter} alt="Twitter Logo" width="39px"/></a></div>

                    </div>
                </div>
                  </li>
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Company</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="About" to="/terms-and-conditions" target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Terms & Conditions</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Jobs" to='/complaint-policy' target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Complaints Policy</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Privacy" to="/privacy-policy" target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Privacy Policy</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Terms of use" to="/faqs"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">FAQs</NavLink>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Hotels</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Makkah')} aria-label="United States of America travel guide"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Makkah</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Madinah')} aria-label="Hotels in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Madinah</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Riyadh')} aria-label="Vacation rentals in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Riyadh</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Jeddah')} aria-label="Vacation packages in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Jeddah</a>
                        </div>
                      </li>
                    </ul>
                  </li>
                 
                </ul>
                <div>
                  <hr class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr" />
                </div>
                <ul class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-column uitk-layout-flex-flex-wrap-wrap no-bullet">               
                  <li class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width" role="listitem">
                    <div class="uitk-text uitk-type-start overflow-wrap uitk-type-200 uitk-type-regular uitk-text-default-theme">
                      <span>Copyright © {moment().format('YYYY')} All Rights Reserved</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
    </>        
    );
}

export default Footer;