import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faLocation,
  faStar,
  faMinus,
  faPlus,
  faRefresh,
  faHandPointRight
} from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DateRangePicker, Popover, Whisper,Modal ,Placeholder,Button} from 'rsuite'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'

import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
import { setSearchQuery, setHotelCheckout,setHotelDetail } from '../../Redux/hotelActions'
import { Helmet } from 'react-helmet'
const { beforeToday } = DateRangePicker
function HotelDetail () {
  const location = useLocation()
  const navigate = useNavigate()
  const todayDate = new Date()
  const dispatch = useDispatch()
  var endpoint = ApiEndPoint()
  const apitoken = Hotelapitoken()
  const { index } = location.state || {}
  const [activeButton, setActiveButton] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([])
  const [displayCount, setDisplayCount] = useState(10)
  // *********************************************************
  const [priceConversion, setPriceConversion] = useState(false)
  const [currencyRates, setCurrencyRates] = useState([])
  const [CancellationArr, setCancellationArr] = useState([]);
  const [roomName, setRoomName] = useState("");
  const [loadingCancellation, setLoadingCancellation] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const BaseCurrRates = useSelector(state => state.currency.baseCurrRates)
  const SelectedCurrRates = useSelector(
    state => state.currency.selectedCurrRates
  )
  const HotelDetail = useSelector(state => state.hotelDetail.hotelDetail)
  const ReduxSearchData = useSelector(state => state.hotelSearch.searchQuery)
  // *************************************************************
  // Modify Search Start
  const [numberOfNights, setNumberOfNights] = useState('')
  const [isWhisperOpen, setIsWhisperOpen] = useState(false)
  const [isCustomSearch, setIsCustomSearch] = useState(false)
  const [childAgesError, setChildAgesError] = useState({})
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [dateRange, setDateRange] = useState({
    checkindate: null,
    Checkoutdate: null
  })
  const [personData, setPersonData] = useState({
    adult: 2,
    children: 0,
    room: 1
  })
  const [rooms, setRooms] = useState([
    {
      adults: 2,
      children: 0,
      childrenAges: []
    }
  ])
  const handleViewMore = () => {
    setDisplayCount(prevCount => prevCount + 10)
  }
  let Checkin = moment(ReduxSearchData.check_in)
  let checkout = moment(ReduxSearchData.check_out)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const latitude = HotelDetail.latitude
  const longitude = HotelDetail.longitude
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed'

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const handleButtonClick2 = buttonName => {
    setActiveButton(buttonName)
  }

  // ************************************************
  useEffect(() => {
    let NewArray = []
    let occurrences = {}
    for (let i = 0; i < ReduxSearchData?.Adults.length; i++) {
      let adult = ReduxSearchData?.Adults[i]
      let child = ReduxSearchData?.children[i]
      let pairKey = `${adult}-${child}`

      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1

      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false
        })
      } else {
        NewArray.find(
          entry => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey]
      }
    }
    setResult(NewArray)
    const checkInDatec = new Date(ReduxSearchData.check_in)
    const checkOutDatec = new Date(ReduxSearchData.check_out)
    let Checkin = moment(ReduxSearchData.check_in)
    let checkout = moment(ReduxSearchData.check_out)
    let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
    setNumberOfNights(daysBetween)
    setDateRange([checkInDatec, checkOutDatec])
    const roomsData = ReduxSearchData.rooms_counter.map((_, index) => {
      const childAgesKey = `child_ages${index + 1}`
      return {
        adults: ReduxSearchData.Adults[index],
        children: ReduxSearchData.children[index],
        childrenAges: ReduxSearchData[childAgesKey] // Assuming this is empty for now, you can modify as needed
      }
    })
    setRooms(roomsData)
  }, [ReduxSearchData])
  useEffect(() => {
    var currdata = localStorage.getItem('AllHotelCurr')
    if (currdata !== 'null' && currdata !== null) {
      setPriceConversion(true)
      setCurrencyRates(currdata)
    }
  }, [])
  useEffect(() => {
    const adults = rooms.reduce((acc, item) => acc + item.adults, 0)
    const children = rooms.reduce((acc, item) => acc + item.children, 0)
    const roomss = rooms.length
    setPersonData(prevPersonData => ({
      ...prevPersonData,
      adult: adults,
      children: children,
      room: roomss
    }))
  }, [rooms])
  const calculateMarkup = price => {
    if (Object.keys(HotelDetail).length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(HotelDetail.admin_markup) !== 0) {
        if (HotelDetail.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(HotelDetail.admin_markup)) / 100
        } else {
          markupprice = Number(HotelDetail.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(HotelDetail.customer_markup) !== 0) {
        if (HotelDetail.customer_markup_type === 'Percentage') {
          markupprice = (price * Number(HotelDetail.customer_markup)) / 100
        } else {
          markupprice = Number(HotelDetail.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }

      if (Number(finalpricemarkup) % 1 !== 0) {
        return Number(finalpricemarkup).toFixed(2)
      }
      return finalpricemarkup
    }
  }
  const renderPrice = (price, currency) => {
    var selectedcurrency = localStorage.getItem('selectedCurrency')
    var currencyData = JSON.parse(currencyRates)
    if (currencyData.length === 0 || currency === selectedcurrency) {
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )

      const gbpprice = filterroomcurrdata[0].conversion_rates[selectedcurrency] // Use square brackets to access the property
      var baseprice = Number(gbpprice) * Number(price)
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }
  const SelectedRoomQuitity = (event, index) => {
    const qty = event.target.value
    const updatedRooms = selectedRooms.map(room => {
      if (room.index === index) {
        return {
          ...room,
          rooms_qty: qty // update rooms_qty for the specific index
        }
      }
      return room // keep the other rooms unchanged
    })

    setSelectedRooms(updatedRooms)
  }
  const BookRoom = async () => {
    if (hotelSelectedRooms.length === 0) {
      toast.error('Please Select Room First.')
      return
    }
    if (HotelDetail.hotel_provider !== 'Custome_hotel') {
      if (hotelSelectedRooms.length !== result.length) {
        toast.error('Please Select the room according to the search criteria.')
        return
      }
    }
    if (HotelDetail.hotel_provider === 'Custome_hotel') {
      var customresult = checkcustomhotelvalidation()
      if (customresult == false) {
        toast.error('Please Select the room according to the search criteria.')
        return
      }
    }
    setLoading(true)
    const roomdata = {
      rooms_select_data: JSON.stringify(selectedRooms),
      hotel_index: index,
      hotelbeds_select_room: hotelSelectedRooms
    }

    const finaldata = {
      token: apitoken,
      request_data: JSON.stringify(roomdata),
      selected_hotel: JSON.stringify(HotelDetail),
      selected_hotel_details: JSON.stringify({
        check_in: ReduxSearchData?.check_in,
        check_out: ReduxSearchData?.check_out,
        hotel_address: HotelDetail.hotel_address,
        hotel_country: HotelDetail.hotel_country,
        hotel_city: HotelDetail.hotel_city,
        latitude: HotelDetail.longitude,
        longitude: HotelDetail.latitude
      }),
      req_index: index
    }

    try {
      const response = await Axios.post(
        endpoint + '/api/hotels/checkavailability_Live',
        finaldata,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true
          }
        }
      )
      if (response.data.status === 'error') {
        setLoading(false)
        toast.error(response.data.message)
        return
      }
      if (response.data !== '') {
        if (response.data.hotels_data.rooms_list.length === 0) {
          setLoading(false)
          toast.info(
            'Sorry, the selected room is no longer available. Please choose a different room or adjust your search criteria.'
          )
          return
        }
        if (isCustomSearch) {
          dispatch(setSearchQuery(ReduxSearchData))
        }
        dispatch(setHotelCheckout(response.data.hotels_data))
        navigate('/hotel-checkout')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error:', error)
    }
  }
  const checkcustomhotelvalidation = () => {
    let nadult = 0
    let nchild = 0

    selectedRooms.forEach(item => {
      const hroom = HotelDetail.rooms_options[item.index]
      nadult += Number(hroom.adults) * Number(item.rooms_qty)
      nchild += Number(hroom.childs) * Number(item.rooms_qty)
    })
    // console.log(nadult);
    // console.log(nchild);
    if (nadult >= ReduxSearchData.adult && nchild >= ReduxSearchData.child) {
      return true
    } else {
      return false
    }
  }
  const selectedRoomData = (index, event, provider) => {
    event.preventDefault()
    event.stopPropagation()
    if (provider === 'Custome_hotel') {
      if (selectedRooms.some(room => room.index === index)) {
        const updatedSelectedRooms = selectedRooms.filter(
          room => room.index !== index
        )
        const newroom = hotelSelectedRooms.filter(item => item.index !== index)
        setSelectedRooms(updatedSelectedRooms)
        setHotelSelectedRooms(newroom)
      } else {
        const selectedroom = HotelDetail.rooms_options[index]
        const key = { rateKey: selectedroom.booking_req_id, index: index }
        const newSelectedRoom = {
          room_rate_key: JSON.stringify(key),
          rooms_qty: selectedroom.rooms_qty,
          index: index
        }
        setSelectedRooms([...selectedRooms, newSelectedRoom])
        setHotelSelectedRooms([...hotelSelectedRooms, key])
      }
    } else {
      const selectedroom = HotelDetail.rooms_options[index]
      for (let i = 0; i < result.length; i++) {
        if (
          result[i].adult === selectedroom.adults &&
          result[i].child === selectedroom.childs &&
          result[i].qty === selectedroom.rooms_qty
        ) {
          if (result[i].status === false) {
            const key = { rateKey: selectedroom.booking_req_id, index: index }
            const newSelectedRoom = {
              room_rate_key: JSON.stringify(key),
              rooms_qty: selectedroom.rooms_qty,
              index: index
            }
            result[i].status = true
            setSelectedRooms([...selectedRooms, newSelectedRoom])
            setHotelSelectedRooms([...hotelSelectedRooms, key])
          } else {
            if (selectedRooms.some(room => room.index === index)) {
              const updatedSelectedRooms = selectedRooms.filter(
                room => room.index !== index
              )
              const newroom = hotelSelectedRooms.filter(
                item => item.index !== index
              )
              setSelectedRooms(updatedSelectedRooms)
              setHotelSelectedRooms(newroom)
              result[i].status = false
            } else {
              toast.error('Select the room according to the search criteria.')
            }
          }
        }
      }
    }
  }

  // Modify Search

  const handleDateRangeChange = value => {
    setDateRange(value)
  }
  const handleAdultIncrement = roomIndex => {
    // Increment the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults < 6) {
      updatedRooms[roomIndex].adults += 1
      setRooms(updatedRooms)
    }
  }
  const handleAdultDecrement = roomIndex => {
    // Decrement the number of adults for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].adults > 1) {
      updatedRooms[roomIndex].adults -= 1
      updatedRooms[roomIndex].childrenAges.pop()
      setRooms(updatedRooms)
    }
  }
  const handleChildrenIncrement = roomIndex => {
    // Increment the number of children for a specific room, if less than 4
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children < 4) {
      updatedRooms[roomIndex].children += 1
      setRooms(updatedRooms)
    }
  }
  const handleChildrenDecrement = roomIndex => {
    // Decrement the number of children for a specific room
    const updatedRooms = [...rooms]
    if (updatedRooms[roomIndex].children > 0) {
      updatedRooms[roomIndex].children -= 1
      updatedRooms[roomIndex].childrenAges.pop()
      setRooms(updatedRooms)
    }
  }
  const handleCloseWhisper = () => {
    let ageStatus = false
    let messages = {} // Store error messages for each room and child

    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i]

      if (children !== childrenAges.length) {
        for (let j = 0; j < children; j++) {
          if (!childrenAges[j]) {
            // Check if age is missing for a specific child
            messages[`room-${i}-child-${j}`] = `Please select child age.`
            ageStatus = true
          }
        }
      }
    }

    if (ageStatus) {
      setChildAgesError(messages) // Store messages for each specific child missing an age
      return
    }

    setChildAgesError({})
    setIsWhisperOpen(false)
  }
  const handleChildAgeChange = (roomIndex, childIndex, value) => {
    // Update the age of a specific child for a specific room
    const updatedRooms = [...rooms]
    updatedRooms[roomIndex].childrenAges[childIndex] = value
    setRooms(updatedRooms)
  }
  const addRoom = () => {
    // Add a new room with default values
    if (rooms.length < 9) {
      setRooms([...rooms, { adults: 2, children: 0, childrenAges: [] }])
    }
  }
  const removeLastRoom = () => {
    if (rooms.length > 1) {
      // Remove the last room by slicing the array
      setRooms(rooms.slice(0, -1))
    }
  }
  const handleDeleteRoomByIndex = roomIndex => {
    const updatedRooms = rooms.filter((room, index) => index !== roomIndex)
    setRooms(updatedRooms)
  }
  const childAgearray = [
    '1 Year',
    '2 Year',
    '3 Year',
    '4 Year',
    '5 Year',
    '6 Year',
    '7 Year',
    '8 Year',
    '9 Year',
    '10 Year'
  ]
  const speaker = (
    <Popover style={{ width: '21em' }} title='Select Detail'>
      {rooms.map((room, roomIndex) => (
        <div key={roomIndex} className='availability_search'>
          <div
            className='hotel-detail-border d-flex text-center fw-bold mb-2'
            style={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <p className='fw-bold m-1'>Room {roomIndex + 1}</p>
            {roomIndex > 0 && ( // Check if roomIndex is greater than 0
              <button
                className='delete-room-button m-1'
                onClick={() => handleDeleteRoomByIndex(roomIndex)}
              >
                Delete
              </button>
            )}
          </div>
          <div>
            <div className='select-child'>
              <div class='f4878764f1'>
                <label class='a984a491d9 fw-bold' for='group_adults'>
                  Adults
                </label>
              </div>
              <div class='d-flex fff'>
                <button
                  className='adult-modal-btn'
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultDecrement(roomIndex)}
                >
                  <i class='fas fa-minus'>
                    <FontAwesomeIcon size='sm' icon={faMinus} />
                  </i>
                </button>
                <span className='d723d73d5f fw-bold' id='input'>
                  {room.adults}
                </span>
                <button
                  className='adult-modal-btn'
                  name={`adults-${roomIndex}`}
                  onClick={() => handleAdultIncrement(roomIndex)}
                  id='increment'
                >
                  <i class='fas fa-plus'>
                    <FontAwesomeIcon size='sm' icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className='select-child'>
              <div class='f4878764f1'>
                <label class='a984a491d9 fw-bold' for='group_adults'>
                  Children
                </label>
              </div>
              <div class='d-flex fff'>
                <button
                  className='adult-modal-btn'
                  onClick={() => handleChildrenDecrement(roomIndex)}
                  id='decrement'
                >
                  <i class='fas fa-minus'>
                    <FontAwesomeIcon size='sm' icon={faMinus} />
                  </i>
                </button>
                <span className='d723d73d5f fw-bold' id='input'>
                  {room.children}
                </span>
                <button
                  className='adult-modal-btn'
                  onClick={() => handleChildrenIncrement(roomIndex)}
                  id='increment'
                >
                  <i class='fas fa-plus'>
                    <FontAwesomeIcon size='sm' icon={faPlus} />
                  </i>
                </button>
              </div>
            </div>
            <div className='select-child' style={{ justifyContent: 'left' }}>
              {room.children > 0 && (
                <div
                  style={{ flexWrap: 'wrap' }}
                  className='d-flex justify-content-between'
                >
                  {Array.from({ length: room.children }, (_, childIndex) => (
                    <div key={childIndex} className='m-1 '>
                      <label>Child {childIndex + 1} (Age)</label>
                      <select
                        class='form-select child-age-select'
                        name='child1'
                        value={room.childrenAges[childIndex]}
                        onChange={e =>
                          handleChildAgeChange(
                            roomIndex,
                            childIndex,
                            e.target.value
                          )
                        }
                        aria-label='Default select example'
                      >
                        <option selected>Age needed</option>
                        {childAgearray.map((item, index) => (
                          <option key={index} value={index + 1}>
                            {item}
                          </option>
                        ))}
                      </select>
                      {childAgesError[
                        `room-${roomIndex}-child-${childIndex}`
                      ] && (
                        <p className='text-danger'>
                          {
                            childAgesError[
                              `room-${roomIndex}-child-${childIndex}`
                            ]
                          }
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div>
        <div className='select-child availability_search mt-4'>
          <div class='f4878764f1 pe-4'>
            <label class='a984a491d9 fw-bold' for='group_adults'>
              Room
            </label>
          </div>
          <div class='d-flex fff'>
            <button
              className='adult-modal-btn'
              onClick={removeLastRoom}
              id='decrement'
            >
              <i class='fas fa-minus'>
                <FontAwesomeIcon size='sm' icon={faMinus} />
              </i>
            </button>
            <span className='d723d73d5f fw-bold' id='input'>
              {rooms.length}
            </span>
            <button
              className='adult-modal-btn'
              onClick={addRoom}
              id='increment'
            >
              <i class='fas fa-plus'>
                <FontAwesomeIcon size='sm' icon={faPlus} />
              </i>
            </button>
          </div>
          <button
            onClick={handleCloseWhisper}
            className='theme-btn-3 ps-3 pe-3 py-1'
          >
            Apply
          </button>
        </div>
      </div>
    </Popover>
  )
  const GetCustonHotelsRooms = async () => {
    if (dateRange === null) {
      toast.error('Please Select Checkin and Checkout Date.')
      return
    }
    const roomsSummary = rooms.reduce(
      (acc, item, index) => {
        acc.adult += item.adults
        acc.child += item.children
        acc.room += 1
        acc.Adults.push(item.adults)
        acc.children.push(item.children)
        acc[`child_ages${index + 1}`] = item.childrenAges.map(age =>
          age ? parseInt(age, 10) : 2
        )
        acc.rooms_counter.push(acc.room)
        return acc
      },
      {
        adult: 0,
        child: 0,
        room: 0,
        Adults: [],
        children: [],
        rooms_counter: []
      }
    )
    ReduxSearchData.check_in = moment(dateRange[0]).format('YYYY-MM-DD')
    ReduxSearchData.check_out = moment(dateRange[1]).format('YYYY-MM-DD')
    ReduxSearchData.adult = roomsSummary.adult
    ReduxSearchData.child = roomsSummary.child
    ReduxSearchData.children = roomsSummary.children
    ReduxSearchData.Adults = roomsSummary.Adults
    ReduxSearchData.room = roomsSummary.room
    ReduxSearchData.rooms_counter = roomsSummary.rooms_counter
    ReduxSearchData.hotel_provider = HotelDetail.hotel_provider
    ReduxSearchData.token = apitoken
    ReduxSearchData.hotel_Id = HotelDetail.hotel_code
    roomsSummary.rooms_counter.forEach((_, index) => {
      ReduxSearchData[`child_ages${index + 1}`] =
        roomsSummary[`child_ages${index + 1}`]
    })
    for (let i = 0; i < rooms.length; i++) {
      const { children, childrenAges } = rooms[i]

      if (children !== childrenAges.length) {
        toast.info(
          `Room ${
            i + 1
          }: You selected ${children} children but provided age(s) for only ${
            childrenAges.length
          }. Please select the ages for all children.`
        )
        return
      }
    }
    setLoading(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/custom_Search_Hotels',
        ReduxSearchData
      )
      setLoading(false)
      if (response.data.status === 'success') {
        setIsCustomSearch(true)
        dispatch(setHotelDetail(response.data.hotel_details));
        // var x = []; // Initialize an array
        //   x[0] = response.data.hotel_details;
        // dispatch(setSearchResults(x));
        setSelectedRooms([])
        setHotelSelectedRooms([])
        let NewArray = []
        let occurrences = {}
        for (let i = 0; i < ReduxSearchData?.Adults.length; i++) {
          let adult = ReduxSearchData?.Adults[i]
          let child = ReduxSearchData?.children[i]
          let pairKey = `${adult}-${child}`

          occurrences[pairKey] = (occurrences[pairKey] || 0) + 1

          if (occurrences[pairKey] === 1) {
            NewArray.push({
              adult,
              child,
              qty: occurrences[pairKey],
              status: false
            })
          } else {
            NewArray.find(
              entry => entry.adult === adult && entry.child === child
            ).qty = occurrences[pairKey]
          }
        }
        setResult(NewArray)
        const checkInDatec = new Date(ReduxSearchData.check_in)
        const checkOutDatec = new Date(ReduxSearchData.check_out)
        let Checkin = moment(ReduxSearchData.check_in)
        let checkout = moment(ReduxSearchData.check_out)
        let daysBetweend = Math.abs(checkout.diff(Checkin, 'days'))
        setNumberOfNights(daysBetweend)
        setDateRange([checkInDatec, checkOutDatec])
        const roomsData = ReduxSearchData.rooms_counter.map((_, index) => {
          const childAgesKey = `child_ages${index + 1}`
          return {
            adults: ReduxSearchData.Adults[index],
            children: ReduxSearchData.children[index],
            childrenAges: ReduxSearchData[childAgesKey] // Assuming this is empty for now, you can modify as needed
          }
        })
        setRooms(roomsData)
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }
  
  const ShowCancellationPolicy = async (id, cancell_arr, name) => {
    setRoomName(name);
    if (
      HotelDetail.hotel_provider !== "travelenda" &&
      HotelDetail.hotel_provider !== "Stuba"
    ) {
      setLoadingCancellation(false);
      setCancellationArr(cancell_arr);
      setOpen(true);
      return;
    }
    setLoadingCancellation(true);
    var data = {
      token: apitoken,
      booking_req_id: id,
      hotel_provider: HotelDetail.hotel_provider,
    };
    setOpen(true);
    try {
      const response = await Axios.post(
        endpoint + "/api/all_Hotel_Cancellation_Policy",
        data
      );
      if (response.status === 200) {
        if( HotelDetail.hotel_provider === "travelenda"){
          const firstCancellationDeadline = response.data.find(item => item.CancellationDeadline)?.CancellationDeadline;
          const deadline=new Date(firstCancellationDeadline);
          const nowdate= new Date();
          if(deadline < nowdate || firstCancellationDeadline===''){
            setCancellationArr([]);
            setLoadingCancellation(false);
          }else{
            setCancellationArr(response.data);
             setLoadingCancellation(false);
          }

        }else{
          setCancellationArr(response.data);
          setLoadingCancellation(false);
        }
      }
    } catch (error) {
      setLoadingCancellation(false);
      console.error(error);
    }
  };
  return (
    <>
    <Helmet>
      <title>{HotelDetail.hotel_name} | Haramaynhotels.com</title>
      <meta name="description" content="Discover the best hotel options in Makkah and Madinah for your Umrah and Hajj journeys. Haramaynhotels.com offers premium and budget-friendly accommodations near the Holy Mosques."/>
      <meta name="keywords" content="Harmayn Hotels, Makkah hotel bookings, Madina hotel bookings, Makkah Hotels,Medina Hotels,  5-star hotels near Haram, 5 star hotels in Makkah, 5 star hotels in Medina, Cheap Hotels in Makkah, Cheap Hotels in Medina, hotel booking makkah and madina, Luxury Hotels booking in Makkah, Luxury Hotels booking in Madinah, Madinah hotel room rates,  Makkah  hotel room rates, Madinah to Makkah transport, Madinah transportation services, Airport transfers for Umrah, Private cars for Umrah"/>
    </Helmet>
      <ToastContainer />
      <Modal open={open} onClose={handleClose}>
        <Modal.Header className="cancel-modal-header">
          <Modal.Title className="fw-bold">
            {roomName} - Cancellation Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingCancellation ? (
            <Placeholder.Paragraph />
          ) : (
            <div>
              {CancellationArr.length !== 0 ? (
                CancellationArr.map((policy, index) => (
                  <div key={index}>
                    {policy.type === "Fix Amount" ||
                    policy.type === "Amount" ? (
                      <>
                        <h6>
                          <FontAwesomeIcon
                            className="text-success"
                            icon={faHandPointRight}
                          />{" "}
                          {priceConversion == false
                            ? HotelDetail.hotel_curreny
                            : Object.keys(SelectedCurrRates).length === 0
                            ? BaseCurrRates.base_code
                            : SelectedCurrRates.base_code}{" "}
                          {priceConversion == false
                            ? calculateMarkup(Number(policy.amount))
                            : renderPrice(
                                calculateMarkup(Number(policy.amount)),
                                HotelDetail.hotel_curreny
                              )}{" "}
                          {policy.type} will be charged after{" "}
                          {moment(policy.from_date).format("lll")}
                        </h6>
                      </>
                    ) : (
                      <h6 className="mb-2 mt-2">
                        <FontAwesomeIcon
                          className="text-success"
                          icon={faHandPointRight}
                        />{" "}
                        {policy.amount} {policy.type} will be charged after{" "}
                        {moment(policy.from_date).format("lll")}
                      </h6>
                    )}
                  </div>
                ))
              ) : (
                <h6 className="text-danger text-center mb-2 mt-2">
                  Non-Refundable
                </h6>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="ghost">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <div className='uitk-spacing uitk-spacing-margin-medium-inline-six'>
          <div className='p-2'>
          <HotelSearch />
          </div>
            <div style={{ marginTop: '1px', backgroundColor: 'white' }}>
              <Images data={HotelDetail.hotel_gallery} />
              <div
                class='uitk-card-content-section uitk-card-content-section-border-block-end uitk-card uitk-card-roundcorner-all custom-css-flat-border-top custom-css-flat-border-bottom uitk-card-has-primary-theme'
                data-stid='uitk-card-content-section-navbar'
                style={{ position: 'sticky' }}
              >
                <div class='uitk-tabs-container'>
                  <ul
                    class='uitk-tabs uitk-tabs-natural background-primary uitk-tabs-jumplink uitk-tabs-with-border'
                    role='list'
                  >
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-1' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-1'
                        onClick={() => handleButtonClick2('section-1')}
                        class='uitk-tab-anchor'
                        aria-current='true'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Overview</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-3' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-3'
                        onClick={() => handleButtonClick('section-3')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Amenities</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-2' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-2'
                        onClick={() => handleButtonClick2('section-2')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Rooms</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-5' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-5'
                        onClick={() => handleButtonClick('section-5')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Policies</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    <li
                      role='listitem'
                      className={
                        activeButton === 'section-6' ? 'current' : 'uitk-tab'
                      }
                    >
                      <a
                        href='#section-6'
                        onClick={() => handleButtonClick('section-6')}
                        className='uitk-tab-anchor'
                        aria-current='false'
                        role='link'
                        data-toggle='tab'
                        draggable='false'
                      >
                        <span class='uitk-tab-text'>Review & Rating</span>
                      </a>
                      <li
                        aria-hidden='true'
                        class='uitk-tab-highlighter'
                        style={{
                          transform: 'translateX(0px) scaleY(1) scaleX(1)',
                          width: '94.625px'
                        }}
                      ></li>
                    </li>
                    {/* <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li> */}
                  </ul>
                </div>
                <div class='uitk-layout-position uitk-layout-position-right-zero uitk-layout-position-top-zero uitk-layout-position-bottom-zero uitk-layout-position-absolute mt-1 reserve-room'>
                  <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-justify-content-flex-end uitk-spacing'>
                    <div class='uitk-layout-flex uitk-layout-flex-align-content-center uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0 uitk-spacing uitk-spacing-padding-inlineend-three'>
                      <div data-stid='navbar-quick-reserve-button'>
                        {/* <button data-stid="sticky-button" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary">Reserve a room</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='container-fluid mt-2 rounded-3'>
              <section
                id='section-1'
                className='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-spacing uitk-flat-border-top uitk-spacing-margin-blockend-three uitk-spacing-padding-large-inline-six uitk-card-has-primary-theme'
              >
                <h3>{HotelDetail.hotel_name}</h3>
                <div className='row'>
                  <div className='col-md-8'>
                    <i class='awe-icon fa tc fa-check' aria-hidden='true'>
                      <FontAwesomeIcon icon={faLocation} />
                    </i>{' '}
                    {HotelDetail.hotel_address}
                    <div className='row mt-2 mb-2 p-2'>
                      <h6 className='mb-2'>Other Detail</h6>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Hotel Rating
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {HotelDetail.stars_rating === '' ? (
                                <span className='fw-bold'>No Rating</span>
                              ) : (
                                Array(HotelDetail.stars_rating)
                                  .fill(0)
                                  .map((_, index) => (
                                    <i key={index} className='fa fa-star'>
                                      <FontAwesomeIcon icon={faStar} />
                                    </i>
                                  ))
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Minimum Stay
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {daysBetween} {Number(daysBetween)===1?'Night':'Nights'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Check In
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {moment(ReduxSearchData.check_in).format(
                                'DD-MM-YYYY'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Check Out
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {moment(ReduxSearchData.check_out).format(
                                'DD-MM-YYYY'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Country
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {HotelDetail.hotel_country}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              City
                            </p>
                            <p
                              className='mt-0 title'
                              style={{ fontSize: '13px' }}
                            >
                              {HotelDetail.hotel_city}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Boards
                            </p>
                            {HotelDetail.hotel_boards.map((item, index) => (
                              <span
                                key={index}
                                className='mt-0 title'
                                style={{ fontSize: '13px' }}
                              >
                                {item.board_name}{' '}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-2'>
                        <div class='single-tour-feature d-flex align-items-center mb-3'>
                          <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                            <i class='fas fa-check'>
                              <FontAwesomeIcon icon={faCheck} />
                            </i>
                          </div>
                          <div class='single-feature-titles'>
                            <p
                              style={{ fontSize: '13px' }}
                              class='title fw-bold'
                            >
                              Segments
                            </p>
                            {HotelDetail.hotel_segments.map((item, index) => (
                              <span
                                key={index}
                                className='mt-0 title'
                                style={{ fontSize: '13px' }}
                              >
                                {item}{' '}
                              </span>
                            ))}{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <h5 className='mb-2'>Explore the area</h5>
                    <div style={{ width: '100%' }}>
                      <iframe
                        width='100%'
                        height='250'
                        frameborder='0'
                        scrolling='no'
                        marginheight='0'
                        marginwidth='0'
                        src={mapUrl}
                        className='rounded-4'
                      ></iframe>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <div class='lazyload-wrapper uitk-spacing uitk-spacing-margin-block-six'>
                  <div class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme'>
                    <section>
                      <div class='uitk-card-content-section'>
                        <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-small-inline-unset uitk-spacing-padding-medium-inline-unset uitk-spacing-padding-large-inline-three uitk-spacing-padding-extra_large-inline-three uitk-spacing-padding-blockend-three uitk-spacing-padding-blockstart-three about-uit'>
                          <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large sub-about1'>
                            <div class='uitk-layout-flex'>
                              <div class='uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1'>
                                <h2
                                  class='uitk-heading uitk-heading-4'
                                  tabindex='-1'
                                >
                                  About this property
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-about'>
                            <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three sub-about-desc'>
                              <div
                                data-stid='content-item'
                                itemprop='description'
                                class='uitk-layout-grid-item uitk-layout-grid-item-has-column-start'
                              >
                                <div class='uitk-layout-flex'>
                                  <div class='uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1'>
                                    <h3
                                      class='uitk-heading uitk-heading-5'
                                      tabindex='-1'
                                    >
                                      {HotelDetail.hotel_name}
                                    </h3>
                                  </div>
                                </div>
                                <div class='uitk-expando-peek'>
                                  <div class='uitk-expando-peek-main uitk-expando-peek-main-active uitk-expando-peek-main-whitespace'>
                                    <div class='uitk-expando-peek-inner display-lines'>
                                      <div class='uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid'>
                                        <div class='uitk-layout-grid-item'>
                                          <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                            <div data-stid='content-markup'>
                                              <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                {HotelDetail.hotel_address}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class='uitk-layout-grid-item'>
                                          <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                            <div data-stid='content-markup'>
                                              <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                {HotelDetail.description}{' '}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class='uitk-layout-grid-item'>
                                          <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                            <div data-stid='content-markup'>
                                              <div class='uitk-text uitk-type-300 uitk-text-default-theme'>
                                                Other amenities include:{' '}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class='uitk-layout-grid-item'>
                                          <div class='uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme'>
                                            <div data-stid='content-markup'>
                                              <ul
                                                class='uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-two'
                                                role='list'
                                              >
                                                {HotelDetail.hotel_facilities
                                                  .slice(0, displayCount)
                                                  .map((item, index) => (
                                                    <li
                                                      key={index}
                                                      class='uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent'
                                                      role='listitem'
                                                    >
                                                      <span
                                                        aria-hidden='true'
                                                        class='uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2'
                                                      ></span>
                                                      <span class='uitk-typelist-item-child'>
                                                        {item}
                                                      </span>
                                                    </li>
                                                  ))}
                                              </ul>
                                              {displayCount <
                                                HotelDetail.hotel_facilities
                                                  .length && (
                                                <button
                                                  className='btn btn-success'
                                                  onClick={handleViewMore}
                                                >
                                                  View More
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div class='uitk-spacing bex-homepage-module SimpleContainer px-0 my-2'>
                <div
                  data-testid='one-key-banner-1'
                  id='one-key-banner-1'
                  className='px-0'
                >
                  <div>
                    <div
                      class='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme px-0'
                      data-stid='one-key-message-card'
                    >
                      <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three'>
                        <div class='uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item'>
                          <div class='uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six'>
                            <img
                              class='uitk-mark uitk-mark-landscape-oriented'
                              alt='gfgf dfd'
                              src='https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg'
                              id='onekey__standard__always_light'
                            />
                          </div>
                        </div>
                        <div class='uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1'>
                          <div class='uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap'>
                            <div class='uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1'>
                              <div class='uitk-spacing uitk-spacing-padding-inlineend-two'>
                                <h2 class='uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme'>
                                  You'll always get our best prices!
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section id='section-2' className='mt-3'>
                <h2 class='uitk-heading uitk-heading-4 mb-2' tabindex='-1'>
                  Modify Search
                </h2>
                <div className='row'>
                  <div className='col-md-4 col-sm-6 col-12 mt-2'>
                    <DateRangePicker
                      onChange={handleDateRangeChange}
                      value={dateRange}
                      showOneCalendar={isMobile}
                      disabledDate={beforeToday()}
                      format='dd-MM-yyyy'
                      className='w-100 text-left '
                    />
                  </div>
                  <div className='col-md-4 col-sm-6 col-12 field-icon-wrap mt-2'>
                    <Whisper
                      open={isWhisperOpen}
                      placement='bottom'
                      speaker={speaker}
                      preventClose
                    >
                      <button
                        onClick={() => setIsWhisperOpen(true)}
                        className='btn text-left btn-block select-styling  '
                        style={{
                          color: 'black',
                          background: '#fff',
                          borderColor: 'black ',
                          width: '100%'
                        }}
                      >
                        {personData.adult} Adults . {personData.children}{' '}
                        Children . {personData.room} Room
                      </button>
                    </Whisper>
                  </div>
                  <div className='col-md-4 col-sm-6 col-12 mt-2'>
                    <button
                      onClick={GetCustonHotelsRooms}
                      className='btn btn-primary btn-block select-styling search-btn1'
                    >
                      <FontAwesomeIcon className='ms-2 ' icon={faRefresh} />{' '}
                      Modify
                    </button>
                  </div>
                </div>
                <div className='row'>
                  {HotelDetail.rooms_options.map((item, index) => (
                    <div key={index} className='col-md-4 pt-2 pb-2'>
                      <div
                        class='uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-overflow uitk-card-has-primary-theme'
                        data-stid='property-offer-1'
                      >
                        <div class='uitk-layout-flex-item'>
                          <div>
                            <span>
                              <div class='uitk-gallery-carousel uitk-rounded-border-top'>
                                <div
                                  style={{ height: '15em', overflow: 'hidden' }}
                                >
                                  {item.rooms_images &&
                                  item.rooms_images.length > 0 ? (
                                    <img
                                      className='room-card-image'
                                      src={item.rooms_images[0]}
                                      alt={item.room_name}
                                    />
                                  ) : (
                                    <img src={noImage} alt='Default Image' />
                                  )}
                                </div>
                              </div>
                            </span>
                            {HotelDetail.hotel_provider === 'Custome_hotel' && (
                              <div class='uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-right-zero uitk-layout-position-zindex-layer2 uitk-layout-position-absolute'>
                                <div data-stid='outlined-save-button'>
                                  <div class='favorite-button-wrapper'>
                                    <button
                                      type='button'
                                      aria-label='Save Swissôtel Al Maqam Makkah to a trip'
                                      class='favorite-button favorite-button-button-no-label'
                                    >
                                      HH
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class='uitk-spacing uitk-spacing-padding-blockstart-three uitk-spacing-padding-blockend-two uitk-spacing-padding-inline-three'>
                            <div class='uitk-spacing uitk-spacing-padding-small-blockend-half'>
                              <h3 class='uitk-heading uitk-heading-6'>
                                {item.room_name}
                              </h3>
                            </div>
                            <ul className='room_facility mt-2'>
                              {item.rooms_facilities &&
                              item.rooms_facilities.length > 0 ? (
                                <RoomFacilities item={item} />
                              ) : (
                                <li>No facilities available</li>
                              )}
                            </ul>
                            {item.request_type === '1' && (
                              <div className='room-request'>
                                <h6>Room on Request</h6>
                              </div>
                            )}
                            <div class='d-flex justify-content-between mt-2'>
                              <h6>{item.adults} {Number(item.adults)===1?'Adult':'Adults'} </h6>
                              <h6> {item.childs} {Number(item.childs)===1  || Number(item.childs)===0?'Children':'Childrens'}</h6>
                              <h6> {item.rooms_qty} {Number(item.rooms_qty)===1?'Room':'Rooms'}</h6>
                            </div>
                            <div
                              class='uitk-layout-grid mt-2 uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid uitk-spacing uitk-spacing-margin-block-two'
                              data-stid='highlightedMessages-321661323'
                            >
                              <div class='uitk-layout-flex'>
                                <div class='uitk-spacing uitk-spacing-padding-blockstart-half'>
                                <p
                              class="text-success text-center mb-2 mt-2"
                              onClick={() =>
                                ShowCancellationPolicy(
                                  item.booking_req_id,
                                  item.cancliation_policy_arr,
                                  item.room_name
                                )
                              }
                              style={{ color: "green", cursor: "pointer" }}
                            >
                              Show Cancellation Policy
                            </p>
                                  <h6
                                    style={{
                                      fontFamily: '-webkit-body',
                                      fontSize: '18px'
                                    }}
                                  >
                                    {priceConversion == false
                                      ? HotelDetail.hotel_curreny
                                      : Object.keys(SelectedCurrRates)
                                          .length === 0
                                      ? BaseCurrRates.base_code
                                      : SelectedCurrRates.base_code}{' '}
                                    {priceConversion == false
                                      ? (
                                          calculateMarkup(
                                            Number(
                                              item?.room_Promotions_Exist
                                            ) === 1
                                              ? item.rooms_selling_price_Promotion
                                              : item.rooms_total_price
                                          ) / Number(numberOfNights)
                                        ).toFixed(2)
                                      : (
                                          renderPrice(
                                            calculateMarkup(
                                              Number(
                                                item?.room_Promotions_Exist
                                              ) === 1
                                                ? item.rooms_selling_price_Promotion
                                                : item.rooms_total_price
                                            ),
                                            HotelDetail.hotel_curreny
                                          ) / Number(numberOfNights)
                                        ).toFixed(2)}
                                    / Night
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='uitk-card-content-section uitk-layout-flex-item-align-self-stretch uitk-layout-flex-item'>
                          <div class='uitk-spacing uitk-spacing-margin-three'>
                            <div>
                              <div class=' uitk-layout-flex-align-items-flex-end uitk-layout-flex-justify-content-space-between'>
                                <div class='uitk-layout-flex-item'>
                                  <div>
                                    <div data-stid='price-summary'>
                                      <div
                                        class=' uitk-layout-flex-flex-direction-column'
                                        data-test-id='price-summary'
                                      >
                                        <div>
                                          <div
                                            class=' uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                            data-test-id='price-summary-message-line'
                                          >
                                            <div class='uitk-spacing text-center uitk-spacing-padding-block-half'>
                                              <span aria-hidden='true'>
                                                <div class='uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme'>
                                                  <h6
                                                    style={{
                                                      fontFamily:
                                                        '-webkit-body',
                                                      fontSize: '18px'
                                                    }}
                                                  >
                                                    {priceConversion == false
                                                      ? HotelDetail.hotel_curreny
                                                      : Object.keys(
                                                          SelectedCurrRates
                                                        ).length === 0
                                                      ? BaseCurrRates.base_code
                                                      : SelectedCurrRates.base_code}{' '}
                                                    {priceConversion == false
                                                      ? calculateMarkup(
                                                          Number(
                                                            item?.room_Promotions_Exist
                                                          ) === 1
                                                            ? item.rooms_selling_price_Promotion
                                                            : item.rooms_total_price
                                                        )
                                                      : renderPrice(
                                                          calculateMarkup(
                                                            Number(
                                                              item?.room_Promotions_Exist
                                                            ) === 1
                                                              ? item.rooms_selling_price_Promotion
                                                              : item.rooms_total_price
                                                          ),
                                                          HotelDetail.hotel_curreny
                                                        )}
                                                    {item?.room_Promotions_Exist &&
                                                      item.room_Promotions_Exist ===
                                                        '1' && (
                                                        <sub
                                                          style={{
                                                            color: '#808080ad'
                                                          }}
                                                        >
                                                          <del>
                                                            {priceConversion ==
                                                            false
                                                              ? HotelDetail.hotel_curreny
                                                              : Object.keys(
                                                                  SelectedCurrRates
                                                                ).length === 0
                                                              ? BaseCurrRates.base_code
                                                              : SelectedCurrRates.base_code}{' '}
                                                            {priceConversion ===
                                                            false
                                                              ? calculateMarkup(
                                                                  item.rooms_total_price
                                                                )
                                                              : renderPrice(
                                                                  calculateMarkup(
                                                                    item.rooms_total_price
                                                                  ),
                                                                  HotelDetail.hotel_curreny
                                                                )}
                                                          </del>
                                                        </sub>
                                                      )}
                                                  </h6>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            class=' uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap'
                                            data-test-id='price-summary-message-line'
                                          >
                                            <div class='uitk-text text-center uitk-type-start uitk-type-200 uitk-text-default-theme'>
                                              Price For {daysBetween} {Number(daysBetween)===1?'Night':'Nights'}
                                            </div>
                                            <div class='uitk-text text-center uitk-type-start uitk-type-200 uitk-text-default-theme'>
                                              <small className='text-center'>
                                                Vat and Taxes included
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class='mt-3 mt-sm-0 text-center'>
                                      {hotelSelectedRooms.some(
                                        item => item.index === index
                                      ) ? (
                                        <p
                                          onClick={event =>
                                            selectedRoomData(
                                              index,
                                              event,
                                              HotelDetail.hotel_provider
                                            )
                                          }
                                          class='btn btn-sm btn-danger mb-0'
                                        >
                                          Remove
                                        </p>
                                      ) : (
                                        <p
                                          onClick={event =>
                                            selectedRoomData(
                                              index,
                                              event,
                                              HotelDetail.hotel_provider
                                            )
                                          }
                                          class='btn btn-sm btn-success mb-0'
                                        >
                                          Select Room
                                        </p>
                                      )}
                                      {HotelDetail.hotel_provider ===
                                        'Custome_hotel' &&
                                        hotelSelectedRooms.some(
                                          item => item.index === index
                                        ) && (
                                          <div>
                                            <p>Select room Qty</p>
                                            <select
                                              style={{
                                                width: '8em',
                                                margin: 'auto'
                                              }}
                                              onChange={event =>
                                                SelectedRoomQuitity(
                                                  event,
                                                  index
                                                )
                                              }
                                              className='my-1 form-select'
                                              aria-label='Default select example'
                                            >
                                              {Array.from(
                                                {
                                                  length: item.room_Quantity + 1
                                                },
                                                (_, i) => (
                                                  <option key={i} value={i + 1}>
                                                    {i + 1}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
              <div></div>
            </div>
          </div>
        </Layout>
      )}
      <div id='mybutton'>
        <button
          onClick={BookRoom}
          class='btn feedback  select-styling search-btn1 mt-2 detail-view-btn'
        >
          Book Now
        </button>
      </div>
    </>
  )
}

export default HotelDetail
