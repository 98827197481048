import React, { useEffect } from 'react'
import img2 from '../../Images/Pages/contact.png'
import Layout from '../../Components/Layout/Layout'
import wow from 'wowjs'
import { Helmet } from 'react-helmet'
function About () {
  useEffect(() => {
    new wow.WOW().init()
  }, [])
  return (
    <>
    <Helmet>
      <title>About Haramayn Hotels - Our Story, Mission & Commitment to Quality Stays</title>
      <meta name="description" content="Discover the story behind Haramayn Hotels. We offer exceptional hospitality,luxury accommodation and a memorable experience in Makkah and Madina.Learn more about  our mission,values and commitment to providing top-notch service" />
      <meta name="keywords" content="Exclusive hotel experiences in Madina, About Haramayn Hotels, Sustainable hotels in Saudia Arabia, Luxury Hotels in Makkah, Best Hotels in Madina, Haramayn Hotel hospitality services, Luxury Stay at Haramayn Hotels, luxury hotels in Madina" />
    </Helmet>
      <Layout>
        <div className='contact-img'>
          <img src={img2} alt='About Us Top' />
          <h1>About Us</h1>
        </div>
        <section className='about-us-section mt-5'>
          <div className='container'>
            <div className='row'>
              <div
                className='col-sm-12 '
                data-wow-duration='1s'
                data-wow-delay='0.2s'
              >
                {/* <h1 >ABOUT US</h1> */}
                <p>
                  Welcome to Haramayn Hotels, your one-stop travel agency for
                  all your travel needs. We offer a wide range of travel
                  services, including tours to Islamic lands and other
                  world-renowned destinations, flights, hotels, and other
                  excursions.
                </p>
                <p className='mt-2'>
                  Our tours to Islamic lands are designed to give you an
                  authentic experience of the rich culture, history, and
                  traditions of these fascinating destinations. We offer tours
                  to some of the most popular Islamic lands, including Saudi
                  Arabia, Turkey, Egypt, Jordan, and more. Our expert guides
                  will take you on a journey through the ancient sites, vibrant
                  markets, and stunning landscapes of these destinations,
                  providing you with a deeper understanding of the local customs
                  and traditions.
                </p>
                <p className='mt-2'>
                  In addition to our tours to Islamic lands, we also offer tours
                  to other world-renowned destinations. Whether you want to
                  explore the exotic beaches of Bali, the vibrant cities of
                  Europe, or the natural wonders of South America, we have the
                  perfect tour for you. Our team has carefully crafted each
                  itinerary to ensure that you get the most out of your trip,
                  whether it's a short weekend break or an extended vacation.
                </p>
              </div>
            </div>
            <div className='' data-wow-duration='1s' data-wow-delay='0.2s'>
              <h3 className='mt-5'>WE HELP CLIENTS SOLVE COMPLEX PROBLEMS</h3>
              <p>
                At Haramayn Hotels, we understand that planning a trip can be
                overwhelming, which is why we offer a range of travel services
                that include flights, hotels, and other excursions. Our team
                works with top airlines to ensure that you get the best deals on
                flights to your preferred destination. We also partner with
                leading hotel chains to provide you with comfortable and
                affordable accommodation options.
              </p>
              <p className='mt-2'>
                We pride ourselves on providing exceptional customer service,
                and our team of experienced travel agents is available 24/7 to
                answer any queries you may have. Whether you want to book a
                tour, a flight, or a hotel, we are here to provide you with the
                support you need to make your trip a success.
              </p>
              <p className='mt-2'>
                At Haramayn Hotels, we believe that travel is more than just
                visiting a new place; it's about creating unforgettable
                experiences and memories that will last a lifetime. We are
                committed to providing you with a hassle-free and enjoyable
                travel experience that meets all your needs and exceeds your
                expectations.
              </p>
              <h3 className='mt-5'>Our Features</h3>
              <ul style={{ listStyle: 'disc' }}>
                <li className='mt-2'>
                  <p>
                    Personalized Attention: We offer personalized attention to
                    each of our clients, taking the time to understand their
                    travel preferences and needs, and providing them with
                    tailor-made itineraries that suit their budget and
                    interests.
                  </p>
                </li>
                <li className='mt-2'>
                  <p>
                    Expert Advice: Our team of travel experts has extensive
                    knowledge and experience in the travel industry, offering
                    expert advice on destinations, activities, and
                    accommodations that match our clients' expectations and
                    requirements.
                  </p>
                </li>
                <li className='mt-2'>
                  <p>
                    Competitive Pricing: We strive to offer competitive pricing
                    on all our travel packages, ensuring that our clients get
                    the best value for their money.
                  </p>
                </li>
                <li className='mt-2'>
                  <p>
                    Sustainability: We are committed to promoting sustainable
                    and responsible tourism, by supporting local communities and
                    minimizing our impact on the environment.
                  </p>
                </li>
                <li className='mt-2'>
                  <p>
                    24/7 Support: We offer 24/7 support to our clients, ensuring
                    that they have access to assistance and information at any
                    time, and providing them with peace of mind throughout their
                    journey.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default About
