import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import logo from "../../Images/logo.png";
import moment from "moment";
import Layout from "../../Components/Layout/Layout";
import Loading from "../../Components/Loading/Loader";
import { Hotelapitoken,ApiEndPoint } from "../../Components/GlobalData/GlobalData";
import { NavLink, useNavigate } from "react-router-dom";
// import MyBookings from "./MyBookings";

function Emailvarify() {
  const tableRef = useRef(null);
  const API = ApiEndPoint();
  const [step, setStep] = useState(1);
  const token = Hotelapitoken();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [booking, setBooking] = useState([]);

  useEffect(() => {
    if (booking.length > 0) {
      $(document).ready(() => {
        $("#ArrivalList").DataTable().destroy();
        $("#ArrivalList").DataTable();
      });
    }
  }, [booking]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      alert("Please enter your email");
      return;
    }

    const data = {
      token: token,
      email: email,
    };

    // https://api.synchtravel.com/api/hotels/get_Otp_For_Bookings

    setLoader(true);
    try {
      const response = await axios.post(
        `${API}/api/hotels/get_Otp_For_Bookings`,
        data
      );

      if (response.data.status === "error") {
        setMessage(response.data.message);
        setLoader(false);
      } else {
        setLoader(false);
        setStep(2);
        setMessage("");
      }
    } catch (err) {
      console.error(err);
      setMessage(err.response?.data?.message);
      setLoader(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (!otp) {
      alert("Please enter a valid 6-digit OTP.");
      return;
    }

    const DATA = {
      token: token,
      otp: otp,
      email: email,
    };

    setLoader(true);
    // https://api.synchtravel.com/api/hotels/check_Otp_For_Bookings
    try {
      const response = await axios.post(
        `${API}/api/hotels/check_Otp_For_Bookings`,
        DATA
      );
      const data = response.data;

      if (response.data.status === "success") {
        setBooking(data.bookings);
        setMessage("OTP verified successfully!");
        setLoader(false);
        setStep(3);
      } else {
        setMessage(response.data.message || "Invalid OTP. Try again.");
        setLoader(false);
      }
    } catch (error) {
      console.error("OTP verification failed:", error);
      setMessage("Failed to verify OTP. Please try again.");
      setLoader(false);
    }
  };
  return (
    <div>
      {loader && <Loading />}
      <Layout>
        {step === 1 && (
          <div className="container">
            <div className="row">
              <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xxl-4 col-lg-5">
                      <div className="card border">
                        <div
                          className="card-header py-2 text-center "
                        
                        >
                          <img
                            src={logo}
                            alt="logo"
                            height="70"
                            className="mx-auto"
                          />
                        </div>

                        <div className="card-body p-4">
                          <div className="text-center w-75 m-auto">
                            <h4 className="text-dark-50 text-center pb-0 fw-bold">
                              Verify Email
                            </h4>
                            <p className="text-muted mb-4">
                              Enter your email address.
                            </p>
                          </div>

                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label
                                htmlFor="emailaddress"
                                className="form-label"
                              >
                                Email address:
                              </label>
                              <input
                                className="form-control"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                name="email"
                                required
                                placeholder="Enter your email"
                              />
                              {message && (
                                <p className="text-danger">{message}</p>
                              )}
                            </div>

                            <div className="mb-3 mb-0 text-center">
                              <button
                                className="btn search-btn1 btn-primary"
                                type="submit"
                              >
                                Send OTP
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12 text-center">
                          <p className="text-muted">
                            © {moment().format("YYYY")} Haramayn Hotels. All
                            rights reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="container">
            <div className="row">
              <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-xxl-4 col-lg-5">
                      <div class="card border">
                        <div
                          class="card-header py-2 text-center "
                        >
                          <img
                            src={logo}
                            alt="logo"
                            height="70"
                            className="mx-auto"
                          />
                        </div>

                        <div class="card-body p-4">
                          <div class="text-center w-75 m-auto">
                            <h4 class="text-dark-50 text-center pb-0 fw-bold">
                              Verify OTP
                            </h4>
                            <p class="text-muted mb-4">
                              Enter your 6 digit OTP code.
                            </p>
                          </div>

                          <form onSubmit={handleOtpSubmit}>
                            <div class="mb-3">
                              <label for="emailaddress" class="form-label">
                                OTP:
                              </label>
                              <input
                                class="form-control"
                                type="text"
                                onChange={(e) => setOtp(e.target.value)}
                                value={otp}
                                name="otp"
                                required=""
                                placeholder="otp"
                              />
                              {message && <p>{message}</p>}
                            </div>

                            <div class="mb-3 mb-0 text-center">
                              <button
                                class="btn search-btn1 btn-primary"
                                type="submit"
                              >
                                {" "}
                                Verify OTP{" "}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-12 text-center">
                          <p class="text-muted">
                            © {moment().format("YYYY")} Synch Travels. All
                            rights reserved.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {step === 3 && ( 
          <div className="container ">
            <div className="row mt-4">
              <div className="col-md-12">
                <table
                  id="ArrivalList"
                  ref={tableRef}
                  class="table table-striped"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Description</th>
                      <th>Hotel Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {booking.map((item, index) => {
                      const reser = item.reservation_response;
                      const parse = JSON.parse(reser);

                      const reservationreqst = item.reservation_request;
                      const reservationreqstparse =
                        JSON.parse(reservationreqst);

                      return (
                        <tr key={index}>
                          <th>{index + 1}</th>
                          <td>
                            <p>Invoice NO : {item.invoice_no}</p>
                            <p>HCN NO : {item.hcn_Number}</p>
                            <p>Guest Name : {item.lead_passenger} </p>
                          </td>
                          <td>
                            <p>{parse.hotel_details.hotel_name}</p>
                            <p>
                              {parse.hotel_details.rooms.map((item) => (
                                <p>{item.room_name}</p>
                              ))}
                            </p>
                            <p>
                              {parse.hotel_details.checkIn}{" "}
                              <i class="fa-solid fa-arrow-right"></i>{" "}
                              {parse.hotel_details.checkOut}
                            </p>
                          </td>

                          <td>
                            <p
                              className={`badge  ${
                                item.status === "Confirmed"
                                  ? "bg-success"
                                  : "bg-danger"
                              }`}
                            >
                              {item.status}
                            </p>
                          </td>

                          <td>
                            <NavLink
                            target="blank"
                              to={`/hotel-booking-invoice/${item.invoice_no}`}
                              className="btn btn-primary"
                            >
                              View Voucher
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
        
      </Layout>
    </div>
  );
}

export default Emailvarify;
