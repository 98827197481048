import React from "react";
import Layout from "../../Components/Layout/Layout";
import img from './faq.jpg'
import { Helmet } from "react-helmet";
function ComplaintPolicy(){
    return(
        <>
            <Helmet>
              <title>Haramayn Hotels | Complaint Policy and Customer Service Resolution</title>
              <meta name="description" content="Learn about Haramayn Hotels' complaint policy. We address guest concerns with quick resolutions and excellent customer service." />
              <meta name="keywords" content="Hotel complaints policy, Feedback and complaints policy, How to file a complaint with Haramayn Hotels, Report a complaint at Haramayn Hotels, Hotel service complaint resolution, Hotel Complaint Policy, Customer Feedback Policy" />
            </Helmet>
        <Layout>
        <div>
                <img src={img} alt=" complain top"/>
            </div>
            <div className='container'>
          <div className='row mt-3 text-center'>
            <h1 className="h3 fw-bold text-black">Complaints Policy & Procedures</h1>
          </div>
          <div className='row mt-4 '>
            <p className='FontSize15'>
              Haramayn Hotels stands out as the pioneer tour operator, providing
              unparalleled travel experiences for explorers worldwide. Our
              meticulously crafted packages cater to every aspect of your
              journey, including hotel bookings, flights, transfers, and
              activity arrangements. From securing comfortable accommodations to
              facilitating seamless travel arrangements, our dedicated team
              meticulously attends to every detail, ensuring a stress-free and
              enriching experience. Trust in Haramayn Hotels to tailor packages that
              perfectly align with your individual needs and preferences,
              providing peace of mind and unforgettable memories throughout your
              expedition.
            </p>{' '}
          </div>
          <p className='mt-2'>
            How to Submit a Complaint
            <br />
            You can submit a complaint through the following channels:
            <br />
            Email: info@haramaynhotels.com
            <br />
            Phone: +966 509 786777
            <br />
            Online Form: Contact us on our website
            <br />
            Please include as much detail as possible, such as your booking
            reference and the nature of your complaint.
          </p>
        </div>
        </Layout>
        </>
    )
}

export default ComplaintPolicy;