import React, { useState, useEffect } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGlobe,
  faCalendar,
  faLocationDot
} from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Loading from '../Loading/Loader'
import Axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Tooltip, Whisper } from 'rsuite'
import { ToastContainer, toast } from 'react-toastify'
import { setTransferList } from '../../Redux/hotelActions'
import { NewTransferSearchToken, ApiEndPoint,CustomerDomainName,CurrencyConverter } from '../GlobalData/GlobalData'
function TransferNew () {
  const [pickUp, setPickUp] = useState(null)
  const [dropOf, setDropOf] = useState(null)
  const [otherData, setOtherData] = useState({
    pickupDate: null,
    pickupTime: '',
    DropOffDate: null,
    DropOffTime: ''
  })
  const [tripType, setTripType] = useState('One-Way')
  const [Passenger, setPassenger] = useState('1')
  const [Vehicles, setVehicles] = useState('1')
  const [isLoading, setIsLoading] = useState(false)
  const tokenApi = NewTransferSearchToken()
  const endpoint = ApiEndPoint()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basurl=CustomerDomainName();
  const handleOtherData = event => {
    const { value, name } = event.target
    setOtherData({ ...otherData, [name]: value })
  }
  const handlePickupDate = date => {
    setOtherData({ ...otherData, pickupDate: date, DropOffDate: date })
  }
  const handleDropoffDate = date => {
    setOtherData({ ...otherData, DropOffDate: date })
  }
  const handleSelectTripType = event => {
    setTripType(event.target.value)
  }

  const handlePassenger = event => {
    setPassenger(event.target.value)
  }
  const handleVehicles = event => {
    setVehicles(event.target.value)
  }
  const searchTransfer = async () => {
    if (pickUp === null) {
      toast.info('Please Select Pickup Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (Object.keys(pickUp).length === 0) {
      toast.info('Please Select Pickup Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (dropOf === null) {
      toast.info('Please Select DropOff Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (Object.keys(dropOf).length === 0) {
      toast.info('Please Select DropOff Location.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (otherData.pickupDate === '' || otherData.pickupDate === null) {
      toast.info('Please Select Pickup Date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (otherData.pickupTime === '') {
      toast.info('Please Select Pickup Time.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (otherData.DropOffDate === '') {
      toast.info('Please Select Dropoff Date.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (otherData.DropOffTime === '') {
      toast.info('Please Select Dropoff Time.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (Passenger === '') {
      toast.info('Please Enter Passenger.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (Vehicles === '') {
      toast.info('Please Enter Vehicle.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }

    var PickupCountry
    if (
      pickUp.address_components.filter(
        f =>
          JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
      )[0]
    ) {
      PickupCountry = pickUp.address_components.filter(
        f =>
          JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
      )[0]?.long_name
    }

    var PickData = document.getElementsByName('Pickup')
    var PickupLocation = PickData[0].value
    var Picklatitude = pickUp.geometry.location.lat()
    var Picklongitude = pickUp.geometry.location.lng()
    const PickupAddress = pickUp.formatted_address

    var DropOffCountry
    if (
      dropOf.address_components.filter(
        f =>
          JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
      )[0]
    ) {
      DropOffCountry = dropOf.address_components.filter(
        f =>
          JSON.stringify(f.types) === JSON.stringify(['country', 'political'])
      )[0]?.long_name
    }

    var DropData = document.getElementsByName('Dropoff')
    var DropoffLocation = DropData[0].value
    var Dropoflatitude = dropOf.geometry.location.lat()
    var Dropoflongitude = dropOf.geometry.location.lng()
    const DropofAddress = dropOf.formatted_address
    var data = {
      site_URL: basurl+'public/uploads/package_imgs',
      token: tokenApi,
      name_pickup_location_plc: PickupLocation,
      name_drop_off_location_plc: DropoffLocation,
      pick_up_date: moment(otherData.pickupDate).format('YYYY-MM-DD'),
      trip_type: tripType,
      passenger: String(Passenger),
      no_of_vehicles: String(Vehicles),
      pick_up_location_country: PickupCountry,
      startName: String(Picklatitude + ',' + Picklongitude),
      startplacename: PickupAddress,
      startplacecountrycode: PickupCountry,
      destinationName: String(Dropoflatitude + ',' + Dropoflongitude),
      destinationplacename: DropofAddress,
      destinationplacenamecountrycode: DropOffCountry,
      arrtime: formatTimeWithAMPM(otherData.pickupTime),
      retdate: moment(otherData.DropOffDate).format('YYYY-MM-DD'),
      rettime: formatTimeWithAMPM(otherData.DropOffTime)
    }
    setIsLoading(true)
    
    try {
      const response = await Axios.post(
        endpoint + '/api/transfers_search_react',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      if (response.data.message === 'Success') {
        if(response.data.transfers_list.length===0){
          toast.warning('Sorry! no transfer found in given search.', {
            position: toast.POSITION.TOP_RIGHT
          })
          return;
        }
        const currencies = []
        response.data.transfers_list.forEach(transfer => {
          if (!currencies.includes(transfer.sale_currency)) {
            currencies.push(transfer.sale_currency);
          }
        })
        const currencyResponse =await AllCurrency(currencies);
        if (currencyResponse === null || currencyResponse.length === 0) {     
          localStorage.setItem('AllHotelCurr', null)
        } else {
          localStorage.setItem('AllHotelCurr', JSON.stringify(currencyResponse))
        }
        setIsLoading(false)
        dispatch(setTransferList(response.data))
        navigate('/transfer-search')
      } else {
        setIsLoading(false)
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  const AllCurrency = async currencyArray => {
    const token = CurrencyConverter()
    if (currencyArray.length === 0) {
      return [] // Return an empty array if no currencies are provided
    }
    const requests = currencyArray.map(currencynew => {
      const config = {
        method: 'get',
        url: `https://v6.exchangerate-api.com/v6/${token}/latest/${currencynew}`, // Replace with your API URL
        maxBodyLength: Infinity,
        headers: {}
      }

      return Axios.request(config)
        .then(response => {
          return response.data // Return the response data on success
        })
        .catch(error => {
          console.error(error)
          return null // Return null for failed requests
        })
    })
    const results = await Promise.all(requests)
    const newdatcurr = results.filter(response => response !== null) // Filter out null responses
    return newdatcurr
  }
  function formatTimeWithAMPM (timeString) {
    const time = moment(timeString, 'HH:mm')
    return time.format('hh:mma')
  }

  return (
    <>
      {isLoading && <Loading status={true} />}
      <ToastContainer />
      <div className='tab-content'>
        <div className='tab-pane fade show active'>
          <div>
            <div style={{marginLeft:'-16px'}} className='d-flex align-items-center'>
              <div className='set-padding mb-lg-0 p-1 '>
                  <p className='search-title'>Trip Type</p>
                <div className='field-icon-wrap'>                 
                    <select
                      style={{ fontSize: '13px', width: '12em' }}
                      onChange={handleSelectTripType}
                      value={tripType}
                      className='form-select select-styling'
                      aria-label='Default select example'>
                      <option selected value='One-Way'>
                        One-Way
                      </option>
                      <option value='Return'>Return</option>
                      <option value='All_Round'>All_Round</option>
                    </select>
                </div>
              </div>
              <div className=' set-padding mb-lg-0 p-1 '>
                    <p className='search-title'>Passenger</p>
                <div className='field-icon-wrap'>
                    <input
                      style={{ fontSize: '13px', width: '6em' }}
                      type='number'
                      min={1}
                      onChange={handlePassenger}
                      value={Passenger}
                      placeholder='Passenger'
                      className='form-control select-styling'
                    />               
                </div>
              </div>
              <div className=' set-padding mb-lg-0  p-1 '>
                    <p className='search-title'> Vehicles</p>
                <div className='field-icon-wrap'>
                 
                    <input
                      style={{ fontSize: '13px', width: '6em' }}
                      type='number'
                      min={0}
                      onChange={handleVehicles}
                      value={Vehicles}
                      placeholder='Vehicles'
                      className='form-control select-styling'
                    />
                 
                </div>
              </div>
            </div>
            <div className='search-bg-color row'>
              <div className='col-md-6 set-padding  mb-lg-0 p-1 col-lg-2'>
               
                <div className='field-icon-wrap'>
                  <span className='form-control-feedback'>
                    <FontAwesomeIcon icon={faLocationDot} />{' '}
                  </span>
                  <Autocomplete
                    className='form-control search-form-control text-start select-styling '
                    placeholder='Pick-up Location'
                    name='Pickup'
                    apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                    onPlaceSelected={place => {
                      setPickUp(place)
                    }}
                    options={{
                      types: ['establishment', 'geocode'],
                      componentRestrictions: null
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6 set-padding mb-lg-0 p-1 col-lg-2'>               
                <div className='field-icon-wrap'>
                  <span className='form-control-feedback'>
                    <FontAwesomeIcon icon={faLocationDot} />{' '}
                  </span>
                  <Autocomplete
                    className='form-control search-form-control text-start select-styling '
                    placeholder='Drop-off Location'
                    name='Dropoff'
                    apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                    onPlaceSelected={place => {
                      setDropOf(place)
                    }}
                    options={{
                      types: ['establishment', 'geocode'],
                      componentRestrictions: null
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6 set-padding mb-lg-0  p-1 col-lg-2'>          
                <div className='field-icon-wrap'>
                  <span className='form-control-feedback'>
                    <FontAwesomeIcon icon={faCalendar} />{' '}
                  </span>
                  <DatePicker
                    onChange={handlePickupDate}
                    selected={otherData.pickupDate}
                    placeholderText='Pick-up Date'
                    name='pickupDate'
                    minDate={new Date()}
                    dateFormat='dd/MM/yyyy' // Customize date format as needed
                    className='form-control text-start select-styling ps-5'
                  />
                </div>
              </div>
              <div className='col-md-6 set-padding mb-lg-0  p-1 col-lg-2'>          
                <div className='field-icon-wrap'>
                  <input
                    type='time'
                    class='form-control'
                    placeholder=' PICK-UP Time'
                    name='pickupTime'
                    value={otherData.pickupTime}
                    onChange={handleOtherData}
                  />
                </div>
              </div>
              <div className='col-md-6 set-padding mb-lg-0 p-1  col-lg-2'>           
                <div className='field-icon-wrap'>
                  <input
                    type='time'
                    class='form-control'
                    placeholder='Drop-off time'
                    name='DropOffTime'
                    value={otherData.DropOffTime}
                    onChange={handleOtherData}
                  />
                </div>
              </div>
              <div className='col-md-6 col-lg-2 set-padding p-1 text-center align-self-end'>
                <button
                style={{lineHeight:'2em'}}
                  onClick={searchTransfer}
                  className='btn btn-primary btn-block select-styling search-btn1'
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransferNew
