import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { Modal,ModalBody,ModalHeader,ModalFooter } from 'reactstrap'
import Axios from 'axios'
import moment from 'moment'
import header from'../../Images/header.png'
import footer from'../../Images/footer.png'
import Loading from '../../Components/Loading/Loader'
import { ToastContainer, toast } from 'react-toastify';
import { ApiEndPoint,Hotelapitoken } from '../../Components/GlobalData/GlobalData'
import { Helmet } from 'react-helmet'
function Confirmation () {
  const { id } = useParams()
  var endpoint=ApiEndPoint();
  const todayDate = new Date();
  const navigation=useNavigate();
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [hotelDetail, setHotelDetail] = useState([]);
  const [invoiceNo, setInvoiceNo] = useState('');
  const [CheckoutRes, setCheckoutRes] = useState({});
  const [hcnNumber, setHcnNumber] = useState('');
  const [hotel, setHotel] = useState([]);
  const [hoteldetail2, setHoteldetail2] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mealList, setMealList] = useState([]);
  const [leadPassenger, setLeadPassenger] = useState([]);
  const [roomPriceSums, setRoomPriceSums] = useState([]);
  const finalRoomPrices = [];
  useEffect(() => {
    getinvoicedetail();
  }, []);
  useEffect(() => {
    total();
  }, [rooms]);
  let Checkin = moment(hoteldetail2.checkIn)
  let checkout = moment(hoteldetail2.checkOut)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const getinvoicedetail = async () => {
    setIsLoading(true);
    var invoiceno = {
      invoice_no: id
    }
    try {
      const response = await Axios.post(endpoint+
        '/api/hotels/view/reservation_Live',
        invoiceno,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      if (response.length !== '') {
        setIsLoading(false);
        var data = response.data.booking_data;
        setHcnNumber(data?.hcn_Number);
        setInvoiceNo(data.invoice_no);
        if(data?.meal_Details){
          var mealdata = JSON.parse(data.meal_Details);
          setMealList(mealdata);
        }
        var reservationrequest = JSON.parse(data.reservation_request);
        setCheckoutRes(reservationrequest);
        var lead=JSON.parse(data.lead_passenger_data);
        setLeadPassenger(lead);
        var hoteldata = JSON.parse(data.reservation_response);
        
        setHotelDetail(
          JSON.parse(response.data.booking_data.reservation_response)
        );
        setHoteldetail2(hoteldata.hotel_details);
        setRooms(hoteldata.hotel_details.rooms);
        setHotel(hoteldata);
        setInvoiceDetail(response.data.booking_data)
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error)
    }
  };
  const printinvoice =()=>{
    window.print();
  };
  const total=()=>{
   let roomsprice=0;
   let markupprice=0;
   let adminmarkupprice=0;
   let clientmarkupprice=0;
   let finalpricemarkup=0;
   var length=rooms.length;
   for(var x=0;x<length;x++){
    roomsprice=Number(rooms[x].room_rates[0].net);
   finalpricemarkup=roomsprice;
   if(Number(hotel.admin_markup) !== 0)
   {
    if(hotel.admin_markup_type === "Percentage")
    {
      markupprice=( roomsprice * Number(hotel.admin_markup))/100;
    }else{
      markupprice= Number(hotel.admin_markup);
     }
      finalpricemarkup +=markupprice
   }
   if(Number(hotel.customer_markup) !== 0)
   {
    if(hotel.customer_markup_type === "Percentage")
    {
      markupprice= (finalpricemarkup * Number(hotel.customer_markup))/100;
    }else{
      markupprice= Number(hotel.customer_markup);
     }
      finalpricemarkup +=markupprice;  
   }
   finalRoomPrices.push(finalpricemarkup);
   setRoomPriceSums(finalRoomPrices);
   }
  };
  const calculatemarkup=(price)=>{
    let roomsprice=Number(price);
    let markupprice=0;
    let finalpricemarkup=Number(price);

    if(Number(hotel.admin_markup) !== 0)
    {
      if(hotel.admin_markup_type === "Percentage")
      {
        markupprice=( roomsprice * Number(hotel.admin_markup))/100;
      }else{
        markupprice= Number(hotel.admin_markup);
      }
        // adminmarkupprice=markupprice;
        finalpricemarkup +=markupprice
        
    }
    if(Number(hotel.customer_markup) !== 0)
    {
      if(hotel.customer_markup_type === "Percentage")
      {
        markupprice= (finalpricemarkup * Number(hotel.customer_markup))/100;
      }else{
        markupprice= Number(hotel.customer_markup);
      }
      //  clientmarkupprice=markupprice;
        finalpricemarkup +=markupprice;
        
    }
    return finalpricemarkup.toFixed(2);
  };
  const toggleModal=()=>{
    setModalOpen(!modalOpen);
  };
  const BookingCancillation =async()=>{
    var apitoken=Hotelapitoken();
    setModalOpen(!modalOpen);
    var data={
      'token':apitoken,
      'invoice_no':invoiceNo
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(endpoint+
        '/api/hotel/reservation/cancell/new_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
          }
        }
      )
      var data=JSON.parse(response.data.view_reservation_details);
      if (data.error) {
           setIsLoading(false);
            toast.info(data.error.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            
          }else{
            getinvoicedetail();
            toast.info('Your reservation has been cancelled', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error)
    }

  };
  const ViewInvoice=()=>{
    navigation(`/hotel-invoice/${invoiceNo}`);
  }
  return (
    <>
     <Helmet>
      <title>Hotel Voucher | Haramaynhotels.com</title>
      <meta name="description" content="Discover the best hotel options in Makkah and Madinah for your Umrah and Hajj journeys. Haramaynhotels.com offers premium and budget-friendly accommodations near the Holy Mosques."/>
      <meta name="keywords" content="Harmayn Hotels, Makkah hotel bookings, Madina hotel bookings, Makkah Hotels,Medina Hotels,  5-star hotels near Haram, 5 star hotels in Makkah, 5 star hotels in Medina, Cheap Hotels in Makkah, Cheap Hotels in Medina, hotel booking makkah and madina, Luxury Hotels booking in Makkah, Luxury Hotels booking in Madinah, Madinah hotel room rates,  Makkah  hotel room rates, Madinah to Makkah transport, Madinah transportation services, Airport transfers for Umrah, Private cars for Umrah"/>
    </Helmet>
    {isLoading && (
      <Loading/>
    )}
    <ToastContainer/>
        <div class="container">
            <img style={{width:'100%'}} src={header} alt="voucher top" />
        </div>
      <div className='container mt-5'>
      <div class="col-12">
					<div class="alert alert-success alert-dismissible fade show my-3" role="alert">
        {invoiceDetail.status==='Room On Request'?(
          <div class="align-items-center">
							<h5 class="alert-heading mb-0">Your request for room has been received.</h5>
              <h5 class="alert-heading mb-0">One of our agent will contact you shortly with the availability of your required room.</h5>
						</div>
        ):(
            <div class="d-sm-flex align-items-center">
							<h5 class="alert-heading mb-0">Your booking has been confirmed! Thank you for choosing our service.</h5>
						</div>
        )}
							
									<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
				</div>
      <div className='text-end hide-on-print ms-2'>
          <button onClick={ViewInvoice} className='btn mb-3 select-styling search-btn1 set-page-ntm-width detail-view-btn btn-success'>
               View Invoice
          </button>
       </div>
        <div className='row'>
          <div className='col-md-8 '>
            <div className='hotel-checkout-shadow mb-4'>
              <div className=''>
                <div className='flight-heading invoice-heading'>
                  <h4>Booking Details</h4>
                </div>
              </div>
              <div class='v-heading-icon clearfix mt-3'>
                <div class='row'>
                  <div class='col-md-8 '>
                    <div class='v-heading-icon-title ms-2 float-left'>
                      <h3>Hotel Information</h3>
                    </div>
                  </div>
                  <div class='col-md-4'></div>
                </div>
              </div>
              <div className='clearfix v-section-info'>
                <ul className='list-items list-items-3 list-items-4  clearfix'>
                  <li>
                    <span class='text-black fw-bold'>Hotel Name:</span>
                    {hoteldetail2.hotel_name}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>Address:</span>
                    {hoteldetail2.destinationName}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>Hotel Category:</span>
                    {hoteldetail2.stars_rating} Stars
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>Booking Status:</span>
                   
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    > {invoiceDetail.status==='In_Progess'?'In Progress':invoiceDetail.status}</p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>
                      Booking Reference No:
                    </span>
                    {hotelDetail.reference_no}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>
                      Invoice No:
                    </span>
                    {invoiceNo}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>
                      HCN No:
                    </span>
                    {hcnNumber}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>Booking Date:</span>
                    {moment(hotel.creationDate).format('LL')}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>Hotel Price:</span>
                    {invoiceDetail.selected_currency} {invoiceDetail.status =='Cancelled' ? '0' : Number(invoiceDetail.exchange_price).toFixed(2)}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>Check-In:</span>
                    {moment(hoteldetail2.checkIn).format('LL')}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>Check-Out:</span>
                    {moment(hoteldetail2.checkOut).format('LL')}
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                  <li>
                    <span class='text-black fw-bold'>Duration:</span>
                    {daysBetween} Nights
                    <p
                      class='f-20 text-black fw-bold'
                      id='makkah_booking_status'
                    ></p>
                  </li>
                </ul>
              </div>
              {mealList.length !==0 && (<>
                <h5 className="p-2 bg-secondary text-light mt-4">Meal Details</h5>
                <div class="mobile-table-scroll mt-2">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Name</th>
                        <th>Adult</th>
                        <th>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mealList.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {item.name}</td>
                          <td>{item.quantity}</td>
                          <td>{invoiceDetail.selected_currency} {(Number(item.totalPrice)*Number(CheckoutRes.exchange_rate)).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </>)}
              <div class='v-heading-icon clearfix mt-3'>
                <div class='row'>
                  <div class='col-md-8'>
                    <div class='v-heading-icon-title ms-2 float-left'>
                      <h3>Room Information</h3>
                    </div>
                  </div>
                  <div class='col-md-4'></div>
                </div>
              </div>
              {rooms.map((item, index) => (
                  <div key={index}  className='clearfix v-section-info'>
                    <div className='flight-heading mt-2'>
                      <h5>{item.room_name}</h5>
                    </div>
                    <ul className='list-items list-items-3 list-items-4  clearfix'>
                      <li>
                        <span class='text-black fw-bold'>Room Status:</span>
                        <p
                          class='f-20 text-black fw-bold'
                          id='makkah_booking_status'
                        >{invoiceDetail.status =='Cancelled' ? 'Cancelled' : invoiceDetail.status==='In_Progess'?'In Progress':invoiceDetail.status}</p>
                      </li>
                      <li>
                        <span class='text-black fw-bold'>Meal Type:</span>
                        {item.room_rates[0].room_board}
                        <p
                          class='f-20 text-black fw-bold'
                          id='makkah_booking_status'
                        ></p>
                      </li>
                      <li>
                        <span class='text-black fw-bold'>Room Quantity:</span>
                        {item.room_rates[0].room_qty}
                        <p
                          class='f-20 text-black fw-bold'
                          id='makkah_booking_status'
                        ></p>
                      </li>
                      <li>
                        <span class='text-black fw-bold'>Passenger:</span>
                        {item.room_rates[0].adults} Adults,{' '}
                        {item.room_rates[0].children} Children
                        <p
                          class='f-20 text-black fw-bold'
                          id='makkah_booking_status'
                        ></p>
                      </li>
                    </ul>
                    <div class='v-heading-icon clearfix mt-3'>
                      <div class='row'>
                        <div class='col-md-8'>
                          <div class='v-heading-icon-title float-left'>
                            <h4 className='ms-2'>Cancellation Policy</h4>
                          </div>
                        </div>
                        <div class='col-md-4'></div>
                      </div>
                      <table class='table'>
                        <thead>
                          <tr>
                            <th scope='col'>From</th>
                            <th scope='col'>Type</th>
                            <th scope='col'>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope='row'> {moment(item.room_rates[0]?.cancellation_policy[0]?.from_date).format('DD-MM-YYYY')}</th>
                            <td>
                            {item.room_rates[0].cancellation_policy &&
                            item.room_rates[0].cancellation_policy.length > 0 ? (
                              new Date(
                                item.room_rates[0].cancellation_policy[0].from_date
                              ) > todayDate ? (
                                <h6> <span style={{ color: 'green' }}>Refundable</span> </h6>
                              ) : (
                                <h6 style={{ color: 'red' }}>Non Refundable</h6>
                              )
                            ) : null}
                            </td>
                            <td>
                            {item.room_rates[0].cancellation_policy &&
                            item.room_rates[0].cancellation_policy.length > 0 ? (
                              new Date(
                                item.room_rates[0].cancellation_policy[0].from_date
                              ) > todayDate ? (
                                <span>{item.room_rates[0].cancellation_policy[0].amount}</span>
                              ) : (
                               
                                <span>{calculatemarkup(item.room_rates[0].cancellation_policy[0].amount)}</span>
                              )
                            ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  ))}
              <div className='text-center d-flex hide-on-print ms-2'>
                <button onClick={toggleModal} className='btn mb-3 me-2 select-styling search-btn1 set-page-ntm-width detail-view-btn  btn-success'>
                  Cancellation
                </button>
                <button onClick={printinvoice} className='btn mb-3 select-styling search-btn1 set-page-ntm-width detail-view-btn btn-success'>
                  Print Voucher
                </button>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='hotel-checkout-shadow'>
              <div className='lead-passenger-detail'>
                <div className='flight-heading invoice-heading'>
                  <h5>Guest Details</h5>
                </div>
                <div className='row mt-2 '>
                <ul className='list-items list-items-3 list-items-4  clearfix'>
                      <li>
                        <span class='text-black fw-bold'>Full Name:</span>
                        {leadPassenger.lead_title+" "+leadPassenger.lead_first_name+" "+leadPassenger.lead_last_name}
                        <p
                          class='f-20 text-black fw-bold'
                          id='makkah_booking_status'
                        ></p>
                      </li>
                      <li>
                        <span class='text-black fw-bold'>Number:</span>
                        {leadPassenger.lead_phone}
                        <p
                          class='f-20 text-black fw-bold'
                          id='makkah_booking_status'
                        ></p>
                      </li>
                      <li>
                        <span class='text-black fw-bold'>Email:</span>
                        {leadPassenger.lead_email}
                        <p
                          class='f-20 text-black fw-bold'
                          id='makkah_booking_status'
                        ></p>
                      </li>
                      </ul>
                 
                </div>
              </div>
            </div>
            <div className='hotel-checkout-shadow mt-5'>
              <div className='lead-passenger-detail'>
                <div className='flight-heading invoice-heading'>
                  <h5>Grand Total</h5>
                </div>
                <div className=' row mt-2 text-center p-5 '>
                  <h4 className='fw-bold'>{invoiceDetail.selected_currency} {invoiceDetail.status =='Cancelled' ? '0.0' : Number(invoiceDetail.exchange_price).toFixed(2)}</h4>
                </div>
              </div>
            </div>
            <div className='hotel-checkout-shadow mt-5'>
              <div className='lead-passenger-detail'>
                <div className='flight-heading invoice-heading'>
                  <h5>Contact Information</h5>
                </div>
                <div className='text-center'>
                  <h6>Feel free to contact us any time.</h6>
                </div>
                <div className='row mt-2 '>
                  <div className='col-md-4 col-4 col-sm-4 mt-2 invoice-lead-gest'>
                    <h6 className=' ms-2'>Phone:</h6>
                  </div>
                  <div className='col-md-8 col-sm-8 col-8 mt-2'>
                    <p className=' ms-2'>+966 509 786777</p>
                  </div>
                  <div className='col-md-4 col-sm-4 col-4 mt-2 invoice-lead-gest'>
                    <h6 className=' ms-2'>Email:</h6>
                  </div>
                  <div className='col-md-8 col-sm-8 col-8 mt-2'>
                    <p className=' ms-2'> info@haramaynhotels.com</p>
                  </div>
                  <div className='col-md-4 col-sm-4 col-4 mt-2 invoice-lead-gest'>
                    <h6 className=' ms-2'>Address:</h6>
                  </div>
                  <div className='col-md-8 col-sm-8 col-8 mb-2 mt-2'>
                    <p className=' ms-2'>
                    Mecca Al-Mokrama Saudi Arabia
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Confirmation 
        </ModalHeader>
        <ModalBody>
        <div className='form-group'>
            <h6>Are you sure you want to cancell this booking ?</h6>
          </div>
        </ModalBody>
        <ModalFooter>
        <button onClick={toggleModal} className='btn btn-secondary' >
            Close
          </button>
          <button
          onClick={BookingCancillation}
            className=' btn btn-danger '
          >
            Confirm
          </button>
        </ModalFooter>
        </Modal>
      <div class="container">
            <img style={{width:'100%'}} src={footer} alt="voucher footer" />
        </div>
    </>
  )
}

export default Confirmation
