import React from 'react';
import gif from '../../Images/logo.png'
import toploader from '../../Images/newloader-1.gif'
const Loading = ({status}) => {
  return (

    <div id="loading-wrapper" style={{zIndex:"9999"}}>
      <div id="loading-text">
      <img src={gif} alt='website loader Top'/>
              <img src={toploader} alt='website loader'/>
           </div>
          
    </div>

  );
};

export default Loading;
