import React, { useEffect, useState } from 'react'
import '../../Components/Carousal/style.css'
import SearchBar from '../../Components/SearchBar/Searchbar'
import Layout from '../../Components/Layout/Layout'
import Loader from '../../Components/Loading/Loader'
import wow from 'wowjs'
import IndexComponents from './IndexComponents'
import { Helmet } from 'react-helmet'
function Home () {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    new wow.WOW().init()
    
    const fetchData = async () => {
      
      await new Promise(resolve => setTimeout(resolve, 2000))
      setIsLoading(false)
    }

    fetchData()
  }, []);


  return (
    <>
      <Helmet>
      <title>Haramaynhotels.com | Your Gateway to Comfort in Makkah and Madinah</title>
      <meta name="description" content="Discover the best hotel options in Makkah and Madinah for your Umrah and Hajj journeys. Haramaynhotels.com offers premium and budget-friendly accommodations near the Holy Mosques."/>
      <meta name="keywords" content="Haramayn Hotels, Makkah hotel bookings, Madina hotel bookings, Makkah Hotels,Medina Hotels,  5-star hotels near Haram, 5 star hotels in Makkah, 5 star hotels in Medina, Cheap Hotels in Makkah, Cheap Hotels in Medina, hotel booking makkah and madina, Luxury Hotels booking in Makkah, Luxury Hotels booking in Madinah, Madinah hotel room rates,  Makkah  hotel room rates, Madinah to Makkah transport, Madinah transportation services, Airport transfers for Umrah, Private cars for Umrah"/>
    </Helmet>
      {isLoading && <Loader />}
      <Layout>
        <SearchBar />
        <IndexComponents />
      
      </Layout>
     
    </>
  )
}

export default Home
