import React, { useEffect, useState,useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import Axios from 'axios'
import moment from 'moment'
import logo from '../../Images/logo.png'
import { ToastContainer, toast } from 'react-toastify'
import {ApiEndPoint,Hotelapitoken} from '../../Components/GlobalData/GlobalData'
import Loading from '../../Components/Loading/Loader'
import header from'../../Images/header.png'
import footer from'../../Images/footer.png'
import { Helmet } from 'react-helmet'
function HotelInvoice () {
  const { id } = useParams()
  var endpoint = ApiEndPoint()
  const todayDate = new Date()
  const [invoiceDetail, setInvoiceDetail] = useState([])
  const [hotelDetail, setHotelDetail] = useState([])
  const [invoiceNo, setInvoiceNo] = useState('')
  const [hcnNumber, setHcnNumber] = useState('');
  const [hotel, setHotel] = useState([])
  const [CheckoutRes, setCheckoutRes] = useState({});
  const [hoteldetail2, setHoteldetail2] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [rooms, setRooms] = useState([])
  const [mealList, setMealList] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [leadPassenger, setLeadPassenger] = useState([])
  const [otherPassenger, setOtherPassenger] = useState([])
  const [roomPriceSums, setRoomPriceSums] = useState([])
  const finalRoomPrices = []
  useEffect(() => {
    getinvoicedetail()
  }, [])
  useEffect(() => {
    total()
  }, [rooms])
  let Checkin = moment(hoteldetail2.checkIn)
  let checkout = moment(hoteldetail2.checkOut)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const getinvoicedetail = async () => {
    setIsLoading(true)
    var invoiceno = {
        invoice_no: id
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/hotels/view/reservation_Live',
        invoiceno,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      if (response.length !== '') {
        setIsLoading(false)
        var data = response.data.booking_data
        setHcnNumber(data?.hcn_Number);
        setInvoiceNo(data.invoice_no)
        var reservationrequest = JSON.parse(data.reservation_request);
        setCheckoutRes(reservationrequest);
        if(data?.meal_Details){
          var mealdata = JSON.parse(data.meal_Details);
          setMealList(mealdata);
        }
        var lead = JSON.parse(data.lead_passenger_data)
        var other = JSON.parse(data.other_adults_data)
        setOtherPassenger(other)
        setLeadPassenger(lead)
        var hoteldata = JSON.parse(data.reservation_response)
        setHotelDetail(
          JSON.parse(response.data.booking_data.reservation_response)
        )
        setHoteldetail2(hoteldata.hotel_details)
        setRooms(hoteldata.hotel_details.rooms)
        setHotel(hoteldata)
        setInvoiceDetail(response.data.booking_data)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  const printinvoice = () => {
    window.print()
  }
  const total = () => {
    let roomsprice = 0
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = 0
    var length = rooms.length
    for (var x = 0; x < length; x++) {
      roomsprice = Number(rooms[x].room_rates[0].net)
      finalpricemarkup = roomsprice
      if (Number(hotel.admin_markup) !== 0) {
        if (hotel.admin_markup_type === 'Percentage') {
          markupprice = (roomsprice * Number(hotel.admin_markup)) / 100
        } else {
          markupprice = Number(hotel.admin_markup)
        }
        // adminmarkupprice=markupprice;
        finalpricemarkup += markupprice
      }
      if (Number(hotel.customer_markup) !== 0) {
        if (hotel.customer_markup_type === 'Percentage') {
          markupprice = (finalpricemarkup * Number(hotel.customer_markup)) / 100
        } else {
          markupprice = Number(hotel.customer_markup)
        }
        finalpricemarkup += markupprice
      }
      finalRoomPrices.push(finalpricemarkup)
      setRoomPriceSums(finalRoomPrices)
    }
  }
  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const BookingCancillation = async () => {
    var apitoken = Hotelapitoken()
    setModalOpen(!modalOpen)
    var data = {
      token: apitoken,
      invoice_no: invoiceNo
    }
    setIsLoading(true)
    try {
      const response = await Axios.post(
        endpoint + '/api/hotel/reservation/cancell/new_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      var data = JSON.parse(response.data.view_reservation_details)
      if (data.error) {
        setIsLoading(false)
        toast.info(data.error.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        getinvoicedetail()
        toast.info('Your reservation has been cancelled', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  return (
    <>
      <Helmet>
      <title>Hotel Invoice | Haramaynhotels.com</title>
      <meta name="description" content="Discover the best hotel options in Makkah and Madinah for your Umrah and Hajj journeys. Haramaynhotels.com offers premium and budget-friendly accommodations near the Holy Mosques."/>
      <meta name="keywords" content="Harmayn Hotels, Makkah hotel bookings, Madina hotel bookings, Makkah Hotels,Medina Hotels,  5-star hotels near Haram, 5 star hotels in Makkah, 5 star hotels in Medina, Cheap Hotels in Makkah, Cheap Hotels in Medina, hotel booking makkah and madina, Luxury Hotels booking in Makkah, Luxury Hotels booking in Madinah, Madinah hotel room rates,  Makkah  hotel room rates, Madinah to Makkah transport, Madinah transportation services, Airport transfers for Umrah, Private cars for Umrah"/>
    </Helmet>
    {isLoading && (
      <Loading/>
    )}
    <ToastContainer/>
    <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Confirmation 
        </ModalHeader>
        <ModalBody>
        <div className='form-group'>
            <h6>Are you sure you want to cancell this booking ?</h6>
          </div>
        </ModalBody>
        <ModalFooter>
        <button onClick={toggleModal} className='btn btn-secondary' >
            Close
          </button>
          <button
          onClick={BookingCancillation}
            className=' btn btn-danger '
          >
            Confirm
          </button>
        </ModalFooter>
        </Modal>
      <div  class='container mt-4' style={{ color: '#000' }}>
        <div class='row mb-4'>
        <img style={{width:'100%'}} src={header} alt="invoice header" />
          
          <div className='col-lg-8 col-md-7 col-sm-7 mt-2'>
            {invoiceDetail.status==='CONFIRMED' ? (
              <h6 class='mb-3'>
                Invoice No : {invoiceNo} | Status: <span style={{color:'#63ab45'}}>{invoiceDetail.status}</span><br/>
                HCN No : {hcnNumber}
              </h6>
            ):(
              <h6 class='mb-3'>
                Invoice No : {invoiceNo} | Status:  {invoiceDetail.status==='In_Progess'?'In Progress':invoiceDetail.status} <br/>
                HCN No : {hcnNumber}
              </h6>
            )}
          </div>
          <div className='col-lg-4 col-md-5 col-sm-5 text-end mt-2'>
            <h6>
              DATE CREATED:{' '}
              {moment(invoiceDetail.creationDate).format('DD-MM-YYYY')}
            </h6>
          </div>
        </div>
        <div class='general-info mt-3'>
          <h6>GENERAL INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Total Pilgrims</th>
                  <th>Adults</th>
                  <th>Children</th>
                  <th>Total Room</th>
                  <th>Check in Date</th>
                  <th>Check out Date</th>
                  <th>Total Nights</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {Number(invoiceDetail.total_adults) +
                      Number(invoiceDetail.total_childs)}
                  </td>
                  <td>{invoiceDetail.total_adults}</td>
                  <td>{invoiceDetail.total_childs}</td>
                  <td>{invoiceDetail.total_rooms}</td>
                  <td>{moment(hoteldetail2.checkIn).format('DD-MM-YYYY')}</td>
                  <td> {moment(hoteldetail2.checkOut).format('DD-MM-YYYY')}</td>
                  <td>{daysBetween}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class='accommodation-details mt-3'>
          <h6>HOTEL DETAILS</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>City</th>
                  <th>Hotel</th>
                  <th>Category</th>
                  <th>Price:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td> {hoteldetail2?.destinationName ? hoteldetail2.destinationName :  hoteldetail2?.zoneName}</td>
                  <td>{hoteldetail2.hotel_name}</td>
                  <td>{hoteldetail2.stars_rating} Stars</td>
                  <td>
                    {invoiceDetail.selected_currency}{' '}
                    {invoiceDetail.status == 'Cancelled'
                      ? '0'
                      : invoiceDetail.exchange_price}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class='accommodation-details mt-3'>
          <h6>ROOM DETAILS</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Board Type</th>
                  <th>Room Quantity</th>
                  <th>Passenger</th>
                </tr>
              </thead>
              <tbody>
                {rooms.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td> {item.room_name}</td>
                    <td> {item.room_rates[0].room_board}</td>
                    <td>{item.room_rates[0].room_qty}</td>
                    <td>
                      {' '}
                      {item.room_rates[0].adults} Adults,{' '}
                      {item.room_rates[0].children} Children
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {mealList.length !==0 && (
          <div class='accommodation-details mt-3'>
                <h6 >MEAL DETAILS</h6>
                <div class="mobile-table-scroll ">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sr</th>
                        <th>Name</th>
                        <th>Adult</th>
                        <th>Total Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mealList.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td> {item.name}</td>
                          <td>{item.quantity}</td>
                          <td>{invoiceDetail.selected_currency} {(Number(item.totalPrice)*Number(CheckoutRes.exchange_rate)).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                </div>
              )}
        <div class='accommodation-details mt-3'>
          <h6>PASSENGERS' INFORMATION</h6>
          <div class='mobile-table-scroll '>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Pilgrims Name</th>
                  <th>Email</th>
                  <th>DOB</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lead Passenger</td>
                  <td>
                    {leadPassenger.lead_title +
                      ' ' +
                      leadPassenger.lead_first_name +
                      ' ' +
                      leadPassenger.lead_last_name}
                  </td>
                  <td>{leadPassenger.lead_email}</td>
                  <td>
                    {moment(leadPassenger.lead_date_of_birth).format(
                      'DD-MM-YYYY'
                    )}
                  </td>
                  <td>{leadPassenger.lead_country} </td>
                </tr>
                {otherPassenger.length > 0 &&
                  otherPassenger.map((item, index) => (
                    <tr key={index}>
                      <td>Passenger {index + 2}</td>
                      <td>{item.title + ' ' + item.name}</td>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>{item.nationality}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='text-center hide-on-print justify-content-center d-flex  ms-2'>
          
          <button
            onClick={printinvoice}
            className='btn mb-3 select-styling search-btn1 set-page-ntm-width detail-view-btn btn-success'
          >
            Print Invoice
          </button>
        </div>
         <img style={{width:'100%'}} src={footer} alt="invoice footer" />
      </div>
    </>
  )
}
export default HotelInvoice
