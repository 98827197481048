import React, { useState, useEffect } from 'react'
import logo from '../../Images/logo.png'
import Axios from 'axios'
import { useDispatch } from 'react-redux'
import { CurrencyConverter } from '../GlobalData/GlobalData'
import { Drawer} from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { setSelectedCurr, setBaseCurr} from "../../Redux/hotelActions";
function Navbar () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('GBP')
  const options = [
    { value: "GBP", label: "GBP", imgSrc: "https://flagcdn.com/w320/gb.png" },
    { value: "SAR", label: "SAR", imgSrc: "https://flagcdn.com/w320/sa.png" },
    { value: "USD", label: "USD", imgSrc: "https://flagcdn.com/w320/us.png" },
    { value: "EUR", label: "EUR", imgSrc: "https://flagcdn.com/w320/eu.png" },
    { value: "PKR", label: "PKR", imgSrc: "https://flagcdn.com/w320/pk.png" },
  ];

  useEffect(() => {
    AllCurrency();
    const storedCurrency = localStorage.getItem('selectedCurrency')
    if (storedCurrency) {
      setSelectedCurrency(storedCurrency)
    }else{
      localStorage.setItem("selectedCurrency", "GBP");
    }
  }, []);
  const AllCurrency = () => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/GBP', // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    };

    Axios.request(config)
      .then(response => {
        // Handle the response data here
        dispatch(setBaseCurr(response.data));
      })
      .catch(error => {
        dispatch(setBaseCurr(null));
        // Handle errors here
        console.error(error)
      })
  }
const handlecurrchange = (event) => {
  setSelectedCurrency(event.target.value);
  SelectedCurrRates(event.target.value);
  localStorage.setItem("selectedCurrency", event.target.value);
};
const SelectedCurrRates = (currency) => {
  var token = CurrencyConverter();
  const config = {
    method: "get",
    url:
      "https://v6.exchangerate-api.com/v6/" + token + "/latest/" + currency, // Replace with your API URL
    maxBodyLength: Infinity,
    headers: {},
  };

  Axios
    .request(config)
    .then((response) => {
      response.data.selectedcurrency = currency;
      dispatch(setSelectedCurr(response.data));
    })
    .catch((error) => {
      dispatch(setSelectedCurr(null));
      console.error(error);
    });
};
  return (
    <>
    <Drawer style={{zIndex:'9999' ,width:'100%'}} open={open} onClose={() => setOpen(false)}>
    <Drawer.Header>
          <Drawer.Title>Haramayn Hotels</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div onClick={() => setOpen(false)} className='mobile-nav-new'>
          <NavLink to='/'>
            <h4>Home</h4>
            </NavLink>
          </div>
          <div onClick={() => setOpen(false)} className='mt-1 mobile-nav-new'>
          <NavLink to='/about-us'>
            <h4>About us</h4>
            </NavLink>
          </div>
          <div onClick={() => setOpen(false)} className='mt-1 mobile-nav-new'>
          <NavLink to='/contact-us'>
            <h4>Contact us</h4>
            </NavLink>
          </div>
          <div onClick={() => setOpen(false)} className='mt-1 mobile-nav-new'>
          <NavLink to='/view-bookings'>
            <h4>My Bookings</h4>
            </NavLink>
          </div>
        </Drawer.Body>
      </Drawer>
      <header style={{ boxShadow: '0 2px 1px 0 rgba(12,14,28,.12)'}}>
        <div className='navbar-top'>
          <div className='row'>
            <div className='col-lg-4 col-md-4 navbar-img col-sm-12'>
              <div className=' d-flex justify-content-between align-items-center'>
                <NavLink to='/'><img  src={logo} alt="navbar logo" height={70} /></NavLink>
              <h4 onClick={() => setOpen(true)} className='mobile-bar'><FontAwesomeIcon icon={faBars}/></h4>
              </div>
            </div>
            <div className='col-lg-8 col-md-8 col-sm-12' style={{margin:'auto'}}>
              <div className='navbar-top-right'>
                <div className='d-flex top-button-effect non'>
                <NavLink to='/'>
                  <button className='btn btn-success d-flex non'>
               <h5 className='non' style={{color:'black', fontSize:'14px'}}>Home</h5> </button></NavLink> </div>
                {/* <div className='d-flex top-button-effect non'>  
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}> Activities</h5> </button>
                </div> */}
                <div className='d-flex top-button-effect non'>
                <NavLink to='/about-us'>
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}> About us</h5> </button>
                  </NavLink>
                </div>
                <div className='d-flex top-button-effect non'>
                <NavLink to='/contact-us'>
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}> Contact us</h5> </button>
                  </NavLink>
                </div>
                <div className='d-flex top-button-effect non'>
                <NavLink to='/view-bookings'>
                  <button className='btn btn-success non'> <h5 style={{color:'black', fontSize:'14px'}}>My Bookings</h5> </button>
                  </NavLink>
                </div>
                <div className='d-flex top-button-effect non'>
                <select
                  class="form-select"
                  value={selectedCurrency}
                  onChange={handlecurrchange}
                  aria-label="Default select example"
                >
                  {options.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Navbar
